<template>
  <div>
    <div v-if="field.type === 'number'" class="py-1 px-1 relative" @click.stop>
      <v-select v-model="field.preferences.format" :items="numberFormats" label="Format" @change="save"></v-select>
    </div>
    <div v-if="['number'].includes(field.type)" class="py-1 px-1 relative" @click.stop>
      <v-select v-model="field.preferences.align" :items="numberAlign" label="Align" @change="save"></v-select>
    </div>
    <!-- <div v-if="['select', 'radio'].includes(field.type) && !defaultField" class="py-1.5 px-1 relative" @click.stop>
      <select
        :placeholder="$t('defaultValue')"
        dir="auto"
        class="select select-bordered select-xs w-full max-w-xs h-7 !outline-0 font-normal bg-transparent"
        @change="
          [(field.defaultValue = $event.target.value), !field.defaultValue && delete field.defaultValue, save()]
        "
      >
        <option value="" :selected="!field.defaultValue">
          {{ $t('none') }}
        </option>
        <option
          v-for="(option, index) in field.options || []"
          :key="option.uuid"
          :value="option.value || `${$t('option')} ${index + 1}`"
          :selected="field.defaultValue === (option.value || `${$t('option')} ${index + 1}`)"
        >
          {{ option.value || `${$t('option')} ${index + 1}` }}
        </option>
      </select>
      <label :style="{ backgroundColor }" class="absolute -top-1 left-2.5 px-1 h-4" style="font-size: 8px">
        {{ $t('defaultValue') }}
      </label>
    </div> -->
    <div v-if="['text', 'number'].includes(field.type) && !defaultField" class="py-1 px-1 relative" @click.stop>
      <v-text-field v-model="field.defaultValue" :type="field.type" label="Default value" @blur="save"></v-text-field>
    </div>
    <div v-if="['text', 'cells'].includes(field.type)" class="py-1.5 px-1 relative" @click.stop>
      <v-select
        v-model="validationValue"
        :items="validations"
        label="Validation"
        @change="onChangeValidation"
      ></v-select>
    </div>
    <!-- Custom -->
    <!-- <div
      v-if="['text', 'cells'].includes(field.type) && field.validation && checkValidationPattern()"
      class="py-2 px-1 relative"
      @click.stop
    >
      <input
        ref="validationCustom"
        v-model="field.validation.pattern"
        :placeholder="$t('regexp_validation')"
        dir="auto"
        class="input input-bordered input-xs w-full max-w-xs h-7 !outline-0 bg-transparent"
        @blur="save"
      />
      <label
        v-if="field.validation.pattern"
        :style="{ backgroundColor }"
        class="absolute -top-1 left-2.5 px-1 h-4"
        style="font-size: 8px"
      >
        {{ $t('regexp_validation') }}
      </label>
    </div> -->
    <div v-if="field.type === 'date'" class="px-1 relative" @click.stop>
      <v-select v-model="field.preferences.format" :items="dateFormats" label="Format" @change="save"></v-select>
    </div>
    <div v-if="field.type === 'signature'" class="px-1 relative" @click.stop>
      <v-select v-model="field.preferences.format" :items="signatureFormats" label="Format" @change="save"></v-select>
    </div>
    <div v-if="withRequired && field.type != 'phone' && field.type != 'stamp'" class="px-1 relative" @click.stop>
      <v-switch v-model="field.required" label="Required" color="primary" @change="save"></v-switch>
    </div>
    <div v-if="field.type == 'stamp'" class="px-1 relative" @click.stop>
      <v-switch v-model="withLogo" label="With logo" color="primary" @change="onChangeWithLogo"></v-switch>
    </div>
    <div v-if="field.type == 'checkbox'" class="px-1 relative" @click.stop>
      <v-switch v-model="field.defaultValue" label="Checked" color="primary" @change="save"></v-switch>
    </div>
    <div v-if="field.type == 'date'" class="px-1 relative" @click.stop>
      <v-switch
        v-model="field.readonly"
        label="Set signing date"
        color="primary"
        @change="[(field.defaultValue = $event ? '{{date}}' : null), (field.readonly = $event), save()]"
      ></v-switch>
    </div>
    <div v-if="['text', 'number'].includes(field.type) && !defaultField" class="px-1 relative" @click.stop>
      <v-switch v-model="field.readonly" label="Read only" color="primary" @change="save"></v-switch>
    </div>
    <v-divider v-if="field.type != 'stamp'" class="pb-1 mt-1" />
    <div
      v-if="field.type != 'stamp'"
      class="d-flex align-center py-2 px-1 relative"
      style="cursor: pointer; width: 100%"
      @click="$emit('click-description')"
    >
      <v-icon class="me-1">mdi-information-outline</v-icon>
      <span class="text-sm">
        Description
      </span>
    </div>
    <!-- <li v-if="field.type != 'stamp'">
      <label class="label-text cursor-pointer text-center w-full flex items-center" @click="$emit('click-condition')">
        <IconRouteAltLeft width="18" />
        <span class="text-sm">
          {{ $t('condition') }}
        </span>
      </label>
    </li> -->
    <!-- <li v-if="field.type == 'number'">
      <label class="label-text cursor-pointer text-center w-full flex items-center" @click="$emit('click-formula')">
        <MathFunctionIcon width="18" />
        <span class="text-sm">
          {{ $t('formula') }}
        </span>
      </label>
    </li> -->
    <v-divider v-if="!['multiple', 'radio'].includes(field.type)" class="pb-1 mt-1" />
    <template v-if="withAreas">
      <!-- <li v-for="(area, index) in sortedAreas" :key="index">
        <a href="#" class="text-sm py-1 px-2" @click.prevent="$emit('scroll-to', area)">
          <IconShape :width="20" :stroke-width="1.6" />
          {{ $t('page') }}
          <template v-if="template.schema.length > 1"
            >{{ template.schema.findIndex(item => item.attachmentUuid === area.attachmentUuid) + 1 }}-</template
          >{{ area.page + 1 }}
        </a>
      </li> -->
      <div
        v-if="(field.areas && !field.areas.length) || !['radio', 'multiple'].includes(field.type)"
        class="d-flex align-center py-2 px-1 relative"
        style="cursor: pointer; width: 100%"
        @click="$emit('set-draw', { field })"
      >
        <component :is="$options.NewSectionIcon" class="me-1" />
        <span class="text-sm">
          Draw New Area
        </span>
      </div>
    </template>
    <!-- <li v-if="field.areas?.length === 1 && ['date', 'signature', 'initials', 'text', 'cells'].includes(field.type)">
      <a href="#" class="text-sm py-1 px-2" @click.prevent="copyToAllPages(field)">
        <IconCopy :width="20" :stroke-width="1.6" />
        {{ $t('copy_to_all_pages') }}
      </a>
    </li> -->
  </div>
</template>

<script>
import NewSectionIcon from '@/components/Icons/NewSectionIcon.vue';

export default {
  name: 'FieldSettings',
  components: {},
  inject: ['template', 'save'],
  props: {
    field: {
      type: Object,
      required: true,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: null,
    },
    defaultField: {
      type: Object,
      required: false,
      default: null,
    },
    withRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    withAreas: {
      type: Boolean,
      required: false,
      default: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['set-draw', 'scroll-to', 'click-formula', 'click-description', 'click-condition'],
  data() {
    return {
      numberAlign: [
        {
          value: 'left',
          text: 'Left',
        },
        {
          value: 'center',
          text: 'Center',
        },
        {
          value: 'right',
          text: 'Right',
        },
      ],
      signatureFormats: [
        {
          value: 'any',
          text: 'Any',
        },
        {
          value: 'drawn',
          text: 'Drawn',
        },
        {
          value: 'typed',
          text: 'Typed',
        },
      ],
      validationValue: null,
      withLogo: false,
    };
  },
  computed: {
    schemaAttachmentsIndexes() {
      return (this.template.schema || []).reduce((acc, item, index) => {
        acc[item.attachmentUuid] = index;

        return acc;
      }, {});
    },
    numberFormats() {
      return [
        {
          value: 'none',
          text: this.formatNumber(123456789.567, 'none'),
        },
        {
          value: 'comma',
          text: this.formatNumber(123456789.567, 'comma'),
        },
        {
          value: 'dot',
          text: this.formatNumber(123456789.567, 'dot'),
        },
        {
          value: 'space',
          text: this.formatNumber(123456789.567, 'space'),
        },
      ];
    },
    dateFormats() {
      const formats = [
        'MM/DD/YYYY',
        'DD/MM/YYYY',
        'YYYY-MM-DD',
        'DD-MM-YYYY',
        'DD.MM.YYYY',
        'MMM D, YYYY',
        'MMMM D, YYYY',
        'D MMM YYYY',
        'D MMMM YYYY',
      ];

      if (
        Intl.DateTimeFormat()
          .resolvedOptions()
          .timeZone?.includes('Seoul') ||
        navigator.language?.startsWith('ko')
      ) {
        formats.push('YYYY년 MM월 DD일');
      }

      if (this.field.preferences?.format && !formats.includes(this.field.preferences.format)) {
        formats.unshift(this.field.preferences.format);
      }

      return formats;
    },
    validations() {
      return [
        {
          value: '',
          text: 'None',
        },
        {
          value: '^[0-9]{3}-[0-9]{2}-[0-9]{4}$',
          text: 'SSN',
        },
        {
          value: '^[0-9]{2}-[0-9]{7}$',
          text: 'EIN',
        },
        {
          value: '^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$',
          text: 'Email',
        },
        {
          value: '^https?://.*',
          text: 'URL',
        },
        {
          value: '^[0-9]{5}(?:-[0-9]{4})?$',
          text: 'ZIP',
        },
        {
          value: '^[0-9]+$',
          text: 'Numbers only',
        },
        {
          value: '^[a-zA-Z]+$',
          text: 'Letters only',
        },
      ];
    },
    sortedAreas() {
      return (this.field.areas || []).sort((a, b) => {
        return this.schemaAttachmentsIndexes[a.attachmentUuid] - this.schemaAttachmentsIndexes[b.attachmentUuid];
      });
    },
  },
  mounted() {
    this.validationValue = this.field?.validation?.pattern;
    this.withLogo = this.field.preferences?.withLogo;
  },
  methods: {
    checkSelected(format) {
      return format === this.field.preferences?.format || (format === 'none' && !this.field.preferences?.format);
    },
    onChangeValidation(value) {
      if (value === 'custom') {
        this.field.validation = { pattern: '' };

        this.$nextTick(() => this.$refs.validationCustom.focus());
      } else if (value) {
        this.field.validation = this.field.validation ? this.field.validation : {};
        this.field.validation.pattern = value;

        this.validationValue = value;
      } else {
        delete this.field.validation;
        this.validationValue = null;
      }

      this.save();
    },
    onChangeWithLogo(value) {
      this.field.preferences = this.field.preferences ? this.field.preferences : {};
      this.field.preferences.withLogo = value;
      this.save();
    },
    copyToAllPages(field) {
      const areaString = JSON.stringify(field.areas[0]);

      this.template.documents.forEach(attachment => {
        const numberOfPages = attachment.metadata?.pdf?.number_of_pages || attachment.previewImages.length;

        for (let page = 0; page <= numberOfPages - 1; page += 1) {
          if (!field.areas.find(area => area.attachmentUuid === attachment.uuid && area.page === page)) {
            field.areas.push({ ...JSON.parse(areaString), attachmentUuid: attachment.uuid, page });
          }
        }
      });

      this.$emit('scroll-to', this.field.areas[this.field.areas.length - 1]);

      this.save();
    },
    formatNumber(number, format) {
      if (format === 'comma') {
        return new Intl.NumberFormat('en-US').format(number);
      }
      if (format === 'dot') {
        return new Intl.NumberFormat('de-DE').format(number);
      }
      if (format === 'space') {
        return new Intl.NumberFormat('fr-FR').format(number);
      }
      return number;
    },
    formatDate(date, format) {
      const monthFormats = {
        M: 'numeric',
        MM: '2-digit',
        MMM: 'short',
        MMMM: 'long',
      };

      const dayFormats = {
        D: 'numeric',
        DD: '2-digit',
      };

      const yearFormats = {
        YYYY: 'numeric',
        YY: '2-digit',
      };

      const parts = new Intl.DateTimeFormat([], {
        day: dayFormats[format.match(/D+/)],
        month: monthFormats[format.match(/M+/)],
        year: yearFormats[format.match(/Y+/)],
      }).formatToParts(date);

      return format
        .replace(/D+/, parts.find(p => p.type === 'day').value)
        .replace(/M+/, parts.find(p => p.type === 'month').value)
        .replace(/Y+/, parts.find(p => p.type === 'year').value);
    },

    checkValidationPattern() {
      // const test = this.validations.indexOf(item => {
      //   return item.value === this.field.validation.pattern;
      // });

      // !validations[field.validation.pattern]
      return false;
    },
  },
  NewSectionIcon,
};
</script>
