<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.70078 18L1.05078 12.35L2.47578 10.95L6.72578 15.2L8.12578 16.6L6.70078 18ZM12.3508 18L6.70078 12.35L8.10078 10.925L12.3508 15.175L21.5508 5.97498L22.9508 7.39998L12.3508 18ZM12.3508 12.35L10.9258 10.95L15.8758 5.99998L17.3008 7.39998L12.3508 12.35Z"
      fill-opacity="0.87"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconChecks',
};
</script>
