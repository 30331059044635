import { render, staticRenderFns } from "./MobileFields.vue?vue&type=template&id=c06f7a0c"
import script from "./MobileFields.vue?vue&type=script&lang=js"
export * from "./MobileFields.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports