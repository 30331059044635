<template>
  <div
    class="absolute overflow-visible group"
    :style="positionStyle"
    @pointerdown.stop
    @mousedown="startMouseMove"
    @touchstart="startTouchDrag"
  >
    <!-- Обводка поля цветом подписанта -->
    <div
      v-if="isSelected || isDraw"
      class="top-0 bottom-0 right-0 left-0 absolute border border-1.5 pointer-events-none"
      :style="{ border: `1px solid ${borderColors[submitterIndex]}` }"
    />
    <!-- Если поле это Cells -->
    <div v-if="field.type === 'cells' && (isSelected || isDraw)" class="top-0 bottom-0 right-0 left-0 absolute">
      <div
        v-for="(cellW, index) in cells"
        :key="index"
        class="absolute top-0 bottom-0 border-r"
        :class="borderColors[submitterIndex]"
        :style="{ left: (cellW / area.w) * 100 + '%', 'border-right': `1px solid ${borderColors[submitterIndex]}` }"
      >
        <span
          v-if="index === 0 && editable"
          class="field-resize-icon rounded-xl bg-white absolute cursor-nwse-resize"
          style="left: -4px"
          @mousedown.stop="startResizeCell"
        />
      </div>
    </div>

    <v-sheet
      v-if="field && field.type"
      color="#FAFAFA"
      class="field-options absolute d-inline-flex align-center rounded rounded-b-0 py-1 px-2"
      :class="{ show: isNameFocus || isSelected }"
      style="top: -25px; height: 25px"
    >
      <!-- Изменение подписанта -->
      <field-submitter
        v-model="field.submitterUuid"
        class="border-r"
        :compact="true"
        :editable="editable && (!defaultField || (defaultField.role !== submitter && submitter.name))"
        :allow-add-new="!defaultSubmitters.length"
        :menu-classes="
          'dropdown-content bg-white menu menu-xs p-2 shadow rounded-box w-52 rounded-t-none -left-[1px] mt-[1px]'
        "
        :submitters="template.submitters"
        @input="save"
        @click="$emit('change-selected-area', area)"
      />
      <v-divider vertical />
      <!-- Изменение типа поля -->
      <field-type
        v-model="field.type"
        :button-width="24"
        :editable="editable && !defaultField"
        :button-classes="'px-1'"
        :menu-classes="'bg-white rounded-t-none'"
        @input="[maybeUpdateOptions(), save()]"
        @click="$emit('change-selected-area', area)"
      />
      <!-- Название поля -->
      <span
        v-if="field.type !== 'checkbox' || field.name"
        ref="name"
        :contenteditable="editable && !defaultField"
        dir="auto"
        class="pr-1 cursor-text outline-none block"
        @paste.prevent="onPaste"
        @keydown.enter.prevent="onNameEnter"
        @focus="onNameFocus"
        @blur="onNameBlur"
      >
        {{ optionIndexText }} {{ (defaultField ? field.title || field.name : field.name) || defaultName }}
      </span>
      <!-- Доп настройки поля -->
      <div v-if="!['checkbox', 'phone'].includes(field.type)" class="d-flex align-center ms-1">
        <!-- <label
          v-if="!isContenteditable"
          :for="`required-checkbox-${field.uuid}`"
          class="d-flex align-center cursor-pointer"
          @click.prevent="field.required = !field.required"
          @mousedown.prevent
        >
          <input :id="`required-checkbox-${field.uuid}`" v-model="field.required" type="checkbox" @mousedown.prevent />
          <span class="ms-1">{{ $t('required') }}</span>
        </label> -->
        <!-- @TODO узнать что это -->
        <input
          v-if="isContenteditable"
          :id="`readonly-checkbox-${field.uuid}`"
          type="checkbox"
          class="checkbox checkbox-xs no-animation rounded"
          :checked="!(field.readonly === undefined || field.readonly === null ? field.readonly : true)"
          @change="field.readonly = !(field.readonly === undefined || field.readonly === null ? field.readonly : true)"
          @mousedown.prevent
        />
        <label
          v-if="isContenteditable"
          :for="`readonly-checkbox-${field.uuid}`"
          class="label text-xs"
          @click.prevent="
            field.readonly = !(field.readonly === undefined || field.readonly === null ? field.readonly : true)
          "
          @mousedown.prevent
        >
          {{ $t('editable') }}
        </label>
        <v-menu
          v-model="showSettingsMenu"
          offset-y
          style="background: white"
          :close-on-content-click="false"
          max-width="243"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              ref="settingsButton"
              :title="$t('settings')"
              class="ms-1"
              icon
              x-small
              tabindex="0"
              v-bind="attrs"
              @focus="isSettingsFocus = true"
              @blur="maybeBlurSettings"
              v-on="on"
            >
              <v-icon>mdi-cog-outline</v-icon>
            </v-btn>
          </template>
          <v-card elevation="0" class="px-4 py-2">
            <field-settings
              :field="field"
              :default-field="defaultField"
              :editable="editable"
              background-color="white"
              :with-areas="false"
              @click-formula="isShowFormulaModal = true"
              @click-description="isShowDescriptionModal = true"
              @click-condition="isShowConditionsModal = true"
              @scroll-to="[$emit('change-selected-area', $event), $emit('scroll-to', $event)]"
            />
          </v-card>
        </v-menu>
        <!-- <span
          v-if="field.type !== 'payment' && !isContenteditable"
          class="dropdown dropdown-end"
          @click="renderDropdown = true"
          @touchstart="renderDropdown = true"
        >
          <ul
            v-if="renderDropdown"
            ref="settingsDropdown"
            tabindex="0"
            class="dropdown-content menu menu-xs px-2 pb-2 pt-1 shadow rounded-box w-52 z-10 rounded-t-none"
            :style="{ backgroundColor: 'white' }"
            @dragstart.prevent.stop
            @click="closeDropdown"
            @focusout="maybeBlurSettings"
          >
            <FieldSettings
              :field="field"
              :default-field="defaultField"
              :editable="editable"
              :background-color="'white'"
              :with-required="false"
              :with-areas="false"
              @click-formula="isShowFormulaModal = true"
              @click-description="isShowDescriptionModal = true"
              @click-condition="isShowConditionsModal = true"
              @scroll-to="[$emit('change-selected-area'. $event), $emit('scroll-to', $event)]"
            />
          </ul>
        </span> -->
      </div>
      <!-- Удаление поля -->
      <v-btn x-small icon color="black" class="ms-1" @click.prevent="$emit('remove')">
        <v-icon size="14">mdi-close</v-icon>
      </v-btn>
    </v-sheet>

    <div
      class="d-flex align-center h-full w-full"
      dir="auto"
      :class="[
        isContenteditable ? 'bg-opacity-50' : 'bg-opacity-80',
        isDefaultValuePresent || isContenteditable
          ? alignClasses[field.preferences && field.preferences.align] || ''
          : 'justify-center',
      ]"
      :style="{
        'background-color': bgColors[submitterIndex],
        color: field.defaultValue ? 'black' : borderColors[submitterIndex],
      }"
    >
      <span
        v-if="field"
        class="d-flex justify-center align-center h-full"
        :class="{ 'w-full h-full': ['cells', 'checkbox'].includes(field.type) }"
      >
        <div
          v-if="isDefaultValuePresent || isContenteditable"
          :class="{
            'w-full h-full': ['cells', 'checkbox'].includes(field.type),
            'text-[1.5vw] lg:text-base': !textOverflowChars,
            'text-[1.0vw] lg:text-xs': textOverflowChars,
          }"
        >
          <div
            ref="textContainer"
            class="text-container d-flex align-center px-1"
            :class="{ 'w-full h-full': ['cells', 'checkbox'].includes(field.type) }"
          >
            <!-- <IconCheck
              v-if="field.type == 'checkbox'"
              class="aspect-square mx-auto"
              :class="{ '!w-auto !h-full': area.w > area.h, '!w-full !h-auto': area.w <= area.h }"
            /> -->
            <!-- <v-icon v-if="field.type == 'checkbox'" color="black" width="100%" height="100%">mdi-check</v-icon> -->
            <component :is="$options.CheckIcon" v-if="field.type == 'checkbox'" width="100%" height="100%" />
            <!-- <checks-icon v-if="field.type == 'checkbox'" /> -->
            <span v-else-if="field.type === 'number' && !isContenteditable" class="whitespace-pre-wrap">{{
              formatNumber(field.defaultValue, field.preferences && field.preferences.format)
            }}</span>
            <span v-else-if="field.defaultValue === '{{date}}'">
              Signing date
            </span>
            <div v-else-if="field.type === 'cells' && field.defaultValue" class="w-full flex items-center">
              <div
                v-for="(char, index) in field.defaultValue"
                :key="index"
                class="text-center flex-none"
                :style="{ width: (area.cellW / area.w) * 100 + '%' }"
              >
                {{ char }}
              </div>
            </div>
            <span
              v-else
              ref="defaultValue"
              :contenteditable="isContenteditable"
              class="whitespace-pre-wrap outline-none empty:before:content-[attr(placeholder)] before:text-gray-400"
              :class="{ 'cursor-text': isContenteditable }"
              :placeholder="$t('type_value')"
              @blur="onDefaultValueBlur"
              @paste.prevent="onPaste"
              @keydown.enter.prevent="onDefaultValueEnter"
              >{{ field.defaultValue }}</span
            >
          </div>
        </div>
        <component :is="fieldIcons[field.type]" v-else width="100%" height="100%" style="max-width: 40px" />
      </span>
    </div>
    <div
      v-if="!isContenteditable"
      ref="touchTarget"
      class="absolute top-0 bottom-0 right-0 left-0"
      :class="isDragged ? 'cursor-grab' : 'cursor-pointer'"
      @dblclick="maybeToggleDefaultValue"
    />
    <span
      v-if="field && field.type && editable"
      class="field-resize-icon rounded-xl bg-white absolute cursor-nwse-resize"
      @mousedown.stop="startResize"
      @touchstart="startTouchResize"
    />
    <!-- <Teleport v-if="isShowFormulaModal" :to="modalContainerEl">
      <FormulaModal
        :field="field"
        :editable="editable && !defaultField"
        :build-default-name="buildDefaultName"
        @close="isShowFormulaModal = false"
      />
    </Teleport> -->
    <!-- <Teleport v-if="isShowConditionsModal" :to="modalContainerEl">
      <ConditionsModal :field="field" :build-default-name="buildDefaultName" @close="isShowConditionsModal = false" />
    </Teleport> -->
    <!-- <Teleport v-if="isShowDescriptionModal" :to="modalContainerEl"> -->
    <DescriptionModal
      :is-open="isShowDescriptionModal"
      :field="field"
      :editable="editable && !defaultField"
      :build-default-name="buildDefaultName"
      @close="isShowDescriptionModal = false"
    />
    <!-- </Teleport> -->
  </div>
</template>

<script>
import { v4 } from 'uuid';
import IconButton from '@/components/IconButton.vue';
import CheckIcon from '@/components/Icons/CheckIcon.vue';
// import {
//   TextSizeIcon,
//   WritingSignIcon,
//   CalendarEventIcon,
//   PhotoIcon,
//   CheckboxIcon,
//   PaperclipIcon,
//   SelectIcon,
//   CircleDotIcon,
//   CheckIcon,
//   IconColumns3,
//   LetterCaseUpperIcon,
//   RubberStampIcon,
//   SquareNumber1Icon,
// } from './icons';
import FieldSubmitter from './FieldSubmitter.vue';
import FieldType from './FieldType.vue';
import Field from './Field.vue';
import FieldSettings from './FieldSettings.vue';
// import FormulaModal from './FormulaModal.vue';
// import ConditionsModal from './ConditionsModal.vue';
import DescriptionModal from './DescriptionModal.vue';

export default {
  name: 'FieldArea',
  components: {
    FieldType,
    IconButton,
    CheckIcon,
    FieldSettings,
    // FormulaModal,
    // DotsVerticalIcon,
    DescriptionModal,
    // ConditionsModal,
    FieldSubmitter,
    // XIcon,
  },
  inject: ['template', 'save'],
  props: {
    area: {
      type: Object,
      required: true,
    },
    isDraw: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultField: {
      type: Object,
      required: false,
      default: null,
    },
    defaultSubmitters: {
      type: Array,
      required: false,
      default: () => [],
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    field: {
      type: Object,
      required: false,
      default: null,
    },
    selectedAreaRef: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ['start-resize', 'stop-resize', 'start-drag', 'stop-drag', 'remove', 'scroll-to'],
  data() {
    return {
      isShowFormulaModal: false,
      isShowConditionsModal: false,
      isContenteditable: false,
      isSettingsFocus: false,
      isShowDescriptionModal: false,
      isResize: false,
      isDragged: false,
      isMoved: false,
      renderDropdown: false,
      isNameFocus: false,
      textOverflowChars: 0,
      dragFrom: { x: 0, y: 0 },
      showSettingsMenu: false,
    };
  },
  computed: {
    fieldNames: FieldType.computed.fieldNames,
    fieldIcons: FieldType.computed.fieldIcons,
    isDefaultValuePresent() {
      if (this.field.field?.type === 'radio' && this.field?.areas?.length > 1) {
        return false;
      }
      return this.field?.defaultValue || this.field?.defaultValue === 0;
    },
    modalContainerEl() {
      return this.$el.getRootNode().querySelector('#docuseal_modal_container');
    },
    defaultName() {
      return this.buildDefaultName(this.field, this.template.fields);
    },
    alignClasses() {
      return {
        center: 'justify-center',
        left: 'justify-start',
        right: 'justify-end',
      };
    },
    optionIndexText() {
      if (this.area.option_uuid && this.field.options) {
        return `${this.field.options.findIndex(o => o.uuid === this.area.option_uuid) + 1}.`;
      }
      return '';
    },
    cells() {
      const cells = [];

      let currentWidth = 0;

      while (currentWidth + (this.area.cellW + this.area.cellW / 4) < this.area.w) {
        currentWidth += this.area.cellW || 9999999;

        cells.push(currentWidth);
      }

      return cells;
    },
    submitter() {
      return this.template.submitters.find(s => s.uuid === this.field.submitterUuid);
    },
    submitterIndex() {
      return this.template.submitters.indexOf(this.submitter);
    },
    borderColors() {
      return [
        '#FF5252',
        '#0000C4',
        'border-emerald-500/80',
        'border-yellow-300/80',
        'border-purple-600/80',
        'border-pink-500/80',
        'border-cyan-500/80',
        'border-orange-500/80',
        'border-lime-500/80',
        'border-indigo-500/80',
        'border-red-500/80',
        'border-sky-500/80',
        'border-emerald-500/80',
        'border-yellow-300/80',
        'border-purple-600/80',
        'border-pink-500/80',
        'border-cyan-500/80',
        'border-orange-500/80',
        'border-lime-500/80',
        'border-indigo-500/80',
      ];
    },
    bgColors() {
      return [
        '#FFE5E5D9',
        '#E6EBFFD9',
        'bg-emerald-100',
        'bg-yellow-100',
        'bg-purple-100',
        'bg-pink-100',
        'bg-cyan-100',
        'bg-orange-100',
        'bg-lime-100',
        'bg-indigo-100',
        'bg-red-100',
        'bg-sky-100',
        'bg-emerald-100',
        'bg-yellow-100',
        'bg-purple-100',
        'bg-pink-100',
        'bg-cyan-100',
        'bg-orange-100',
        'bg-lime-100',
        'bg-indigo-100',
      ];
    },
    isSelected() {
      return this.mutableSelectedAreaRef === this.area;
    },
    positionStyle() {
      const { x, y, w, h } = this.area;

      return {
        top: `${y * 100}%`,
        left: `${x * 100}%`,
        width: `${w * 100}%`,
        height: `${h * 100}%`,
      };
    },
    mutableSelectedAreaRef() {
      return this.selectedAreaRef;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'field.defaultValue': function() {
      this.$nextTick(() => {
        if (
          ['date', 'text', 'number'].includes(this.field.type) &&
          this.field.defaultValue &&
          this.$refs.textContainer &&
          (this.textOverflowChars === 0 || this.textOverflowChars - 4 > `${this.field.defaultValue}`.length)
        ) {
          this.textOverflowChars =
            this.$el.clientHeight < this.$refs.textContainer.clientHeight ? `${this.field.defaultValue}`.length : 0;
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (
        ['date', 'text', 'number'].includes(this.field.type) &&
        this.field.defaultValue &&
        this.$refs.textContainer &&
        (this.textOverflowChars === 0 || this.textOverflowChars - 4 > `${this.field.defaultValue}`.length)
      ) {
        this.textOverflowChars =
          this.$el.clientHeight < this.$refs.textContainer.clientHeight ? `${this.field.defaultValue}`.length : 0;
      }
    });
  },
  methods: {
    buildDefaultName: Field.methods.buildDefaultName,
    closeDropdown() {
      document.activeElement.blur();
    },
    maybeToggleDefaultValue() {
      if (['text', 'number'].includes(this.field.type)) {
        this.isContenteditable = true;

        this.$nextTick(() => {
          this.$refs.defaultValue.focus();

          if (this.$refs.defaultValue.innerText.length) {
            window
              .getSelection()
              .collapse(this.$refs.defaultValue.firstChild, this.$refs.defaultValue.innerText.length);
          }
        });
      } else if (this.field.type === 'checkbox') {
        this.field.readonly = !this.field.readonly;
        // eslint-disable-next-line no-unused-expressions
        this.field.defaultValue === true ? delete this.field.defaultValue : (this.field.defaultValue = true);

        this.save();
      } else if (this.field.type === 'date') {
        this.field.readonly = !this.field.readonly;
        // eslint-disable-next-line no-unused-expressions
        this.field.defaultValue === '{{date}}'
          ? delete this.field.defaultValue
          : (this.field.defaultValue = '{{date}}');

        this.save();
      }
    },
    formatNumber(number, format) {
      if (format === 'comma') {
        return new Intl.NumberFormat('en-US').format(number);
      }
      if (format === 'dot') {
        return new Intl.NumberFormat('de-DE').format(number);
      }
      if (format === 'space') {
        return new Intl.NumberFormat('fr-FR').format(number);
      }
      return number;
    },
    // eslint-disable-next-line no-unused-vars
    maybeBlurSettings(e) {
      // if (!e.relatedTarget || !this.$refs.settingsDropdown.contains(e.relatedTarget)) {
      //   this.isSettingsFocus = false;
      // }
    },
    onNameFocus() {
      this.$emit('change-selected-area', this.area);

      this.isNameFocus = true;
      this.$refs.name.style.minWidth = `${this.$refs.name.clientWidth}px`;

      if (!this.field.name) {
        setTimeout(() => {
          this.$refs.name.innerText = ' ';
        }, 1);
      }
    },
    startResizeCell() {
      this.$el.getRootNode().addEventListener('mousemove', this.onResizeCell);
      this.$el.getRootNode().addEventListener('mouseup', this.stopResizeCell);

      this.$emit('start-resize', 'ew');
    },
    stopResizeCell() {
      this.$el.getRootNode().removeEventListener('mousemove', this.onResizeCell);
      this.$el.getRootNode().removeEventListener('mouseup', this.stopResizeCell);

      this.$emit('stop-resize');

      this.save();
    },
    onPaste(e) {
      const text = (e.clipboardData || window.clipboardData).getData('text/plain');

      const selection = window.getSelection();

      if (selection.rangeCount) {
        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(text));
        selection.collapseToEnd();
      }
    },
    onResizeCell(e) {
      if (e.target.id === 'mask') {
        const positionX = e.offsetX / (e.target.clientWidth - 1);

        if (positionX > this.area.x) {
          this.area.cellW = positionX - this.area.x;
        }
      }
    },
    maybeUpdateOptions() {
      delete this.field.defaultValue;

      if (!['radio', 'multiple', 'select'].includes(this.field.type)) {
        delete this.field.options;
      }

      if (['select', 'multiple', 'radio'].includes(this.field.type)) {
        this.field.options ||= [{ value: '', uuid: v4() }];
      }

      (this.field.areas || []).forEach(area => {
        if (this.field.type === 'cells') {
          // eslint-disable-next-line no-param-reassign
          area.cellW = (area.w * 2) / Math.floor(area.w / area.h);
        } else {
          // eslint-disable-next-line no-param-reassign
          delete area.cellW;
        }
      });
    },
    onNameBlur(e) {
      if (e.relatedTarget === this.$refs.settingsButton.$el) {
        this.isSettingsFocus = true;
      }

      const text = this.$refs.name.innerText.trim();

      this.isNameFocus = false;
      this.$refs.name.style.minWidth = '';

      if (text) {
        this.field.name = text;
      } else {
        this.field.name = '';
        this.$refs.name.innerText = this.defaultName;
      }

      this.save();
    },
    onDefaultValueBlur() {
      const text = this.$refs.defaultValue.innerText.trim();

      this.isContenteditable = false;

      if (text) {
        if (this.field.type === 'number') {
          const number = parseFloat(text);

          if (number || number === 0) {
            this.field.defaultValue = parseFloat(text);
          }
        } else {
          this.field.defaultValue = text;
        }

        if (![true, false].includes(this.field.readonly)) {
          this.field.readonly = true;
        }

        this.$refs.defaultValue.innerText = text;
      } else {
        delete this.field.readonly;
        delete this.field.defaultValue;
        this.$refs.defaultValue.innerText = '';
      }

      this.save();
    },
    onDefaultValueEnter() {
      this.$refs.defaultValue.blur();
    },
    onNameEnter() {
      this.$refs.name.blur();
    },
    resize(e) {
      if (e.target.id === 'mask') {
        this.area.w = e.offsetX / e.target.clientWidth - this.area.x;
        this.area.h = e.offsetY / e.target.clientHeight - this.area.y;
      }
    },
    drag(e) {
      if (e.target.id === 'mask') {
        this.isDragged = true;

        this.area.x = (e.offsetX - this.dragFrom.x) / e.target.clientWidth;
        this.area.y = (e.offsetY - this.dragFrom.y) / e.target.clientHeight;
      }
    },
    startDrag(e) {
      this.$emit('change-selected-area', this.area);

      if (!this.editable) {
        return;
      }

      const rect = e.target.getBoundingClientRect();

      this.dragFrom = { x: e.clientX - rect.left, y: e.clientY - rect.top };

      this.$el.getRootNode().addEventListener('mousemove', this.drag);
      this.$el.getRootNode().addEventListener('mouseup', this.stopDrag);

      this.$emit('start-drag');
    },
    startTouchDrag(e) {
      if (e.target !== this.$refs.touchTarget) {
        return;
      }

      document.activeElement?.blur();

      e.preventDefault();

      this.isDragged = true;

      const rect = e.target.getBoundingClientRect();

      this.$emit('change-selected-area', this.area);

      this.dragFrom = { x: rect.left - e.touches[0].clientX, y: rect.top - e.touches[0].clientY };

      this.$el.getRootNode().addEventListener('touchmove', this.touchDrag);
      this.$el.getRootNode().addEventListener('touchend', this.stopTouchDrag);

      this.$emit('start-drag');
    },
    touchDrag(e) {
      if (!this.editable) {
        return;
      }

      const page = this.$parent.$refs.mask.previousSibling;
      const rect = page.getBoundingClientRect();

      this.area.x = Math.min(
        Math.max((this.dragFrom.x + e.touches[0].clientX - rect.left) / rect.width, 0),
        1 - this.area.w
      );
      this.area.y = Math.min(
        Math.max((this.dragFrom.y + e.touches[0].clientY - rect.top) / rect.height, 0),
        1 - this.area.h
      );
    },
    stopTouchDrag() {
      this.$el.getRootNode().removeEventListener('touchmove', this.touchDrag);
      this.$el.getRootNode().removeEventListener('touchend', this.stopTouchDrag);

      if (this.isDragged) {
        this.save();
      }

      this.isDragged = false;

      this.$emit('stop-drag');
    },
    startMouseMove(e) {
      if (e.target !== this.$refs.touchTarget) {
        return;
      }

      document.activeElement?.blur();

      e.preventDefault();

      this.isDragged = true;

      const rect = e.target.getBoundingClientRect();

      this.$emit('change-selected-area', this.area);

      this.dragFrom = { x: rect.left - e.clientX, y: rect.top - e.clientY };

      this.$el.getRootNode().addEventListener('mousemove', this.mouseMove);
      this.$el.getRootNode().addEventListener('mouseup', this.stopMouseMove);

      this.$emit('start-drag');
    },
    mouseMove(e) {
      if (!this.editable) {
        return;
      }

      this.isMoved = true;

      const page = this.$parent.$refs.mask.previousSibling;
      const rect = page.getBoundingClientRect();

      this.area.x = Math.min(Math.max((this.dragFrom.x + e.clientX - rect.left) / rect.width, 0), 1 - this.area.w);
      this.area.y = Math.min(Math.max((this.dragFrom.y + e.clientY - rect.top) / rect.height, 0), 1 - this.area.h);
    },
    stopMouseMove() {
      this.$el.getRootNode().removeEventListener('mousemove', this.mouseMove);
      this.$el.getRootNode().removeEventListener('mouseup', this.stopMouseMove);

      if (this.isMoved) {
        this.save();
      }

      this.isDragged = false;
      this.isMoved = false;

      this.$emit('stop-drag');
    },
    stopDrag() {
      this.$el.getRootNode().removeEventListener('mousemove', this.drag);
      this.$el.getRootNode().removeEventListener('mouseup', this.stopDrag);

      if (this.isDragged) {
        this.save();
      }

      this.isDragged = false;

      this.$emit('stop-drag');
    },
    startResize() {
      this.$emit('change-selected-area', this.area);

      this.$el.getRootNode().addEventListener('mousemove', this.resize);
      this.$el.getRootNode().addEventListener('mouseup', this.stopResize);

      this.$emit('start-resize', 'nwse');
    },
    stopResize() {
      this.$el.getRootNode().removeEventListener('mousemove', this.resize);
      this.$el.getRootNode().removeEventListener('mouseup', this.stopResize);

      this.$emit('stop-resize');

      this.save();
    },
    startTouchResize(e) {
      this.$emit('change-selected-area', this.area);

      this.$refs?.name?.blur();

      e.preventDefault();

      this.$el.getRootNode().addEventListener('touchmove', this.touchResize);
      this.$el.getRootNode().addEventListener('touchend', this.stopTouchResize);

      this.$emit('start-resize', 'nwse');
    },
    touchResize(e) {
      const page = this.$parent.$refs.mask.previousSibling;
      const rect = page.getBoundingClientRect();

      this.area.w = (e.touches[0].clientX - rect.left) / rect.width - this.area.x;
      this.area.h = (e.touches[0].clientY - rect.top) / rect.height - this.area.y;
    },
    stopTouchResize() {
      this.$el.getRootNode().removeEventListener('touchmove', this.touchResize);
      this.$el.getRootNode().removeEventListener('touchend', this.stopTouchResize);

      this.$emit('stop-resize');

      this.save();
    },
  },
  CheckIcon,
};
</script>
