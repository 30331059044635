<template>
  <v-dialog v-model="dialogIsOpen" disabled max-width="560">
    <template #activator="{ attrs, on }">
      <v-btn
        large
        class="mr-3 elevation-0"
        color="primary"
        :class="{ 'mb-3': media.xs }"
        v-bind="attrs"
        v-on="on"
        @click="openDialog"
      >
        {{ $t('button.send') }}
        <v-icon right>mdi-send</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-space-between px-6">
        <span class="text-h5 font-weight-bold">{{ $t('templates.send_document') }}</span>
        <v-btn icon color="primary" @click="dialogIsOpen = false">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="px-6">
        <form-builder
          v-if="!isLoading"
          v-model="initialData"
          :schema="currentSchema"
          :initial-data="initialData"
          @submit="submit"
        >
          <template #footer="{ valid }">
            <v-btn type="submit" color="primary" large :loading="loadingBtn" :disabled="!valid || loadingBtn">{{
              $t('button.send')
            }}</v-btn>
          </template>
        </form-builder>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
// Models
import { schema, schemaWithRoom } from '@/schemas/template.schema';

// Utils
import { createModelData } from '@/schemas/createModelData';
import { fetchRooms } from '@/services/select';

// Http
import client from '@/http/client';

// Components
import FormBuilder from '@/components/schema/FormBuilder.vue';

// Constants
import { AGREEMENTS } from '@/constants/routes';

// Services
import contractService from '@/services/contract';
import usersService from '@/services/users';
import notificationService from '@/services/notification';

export default {
  name: 'SendFormModal',
  components: { FormBuilder },
  inject: ['media', 'template'],
  data() {
    return {
      disabledSendForm: true,
      dialogIsOpen: false,
      isLoading: false,
      initialData: createModelData(schema),
      isHasRooms: false,
      documentId: null,
      loadingBtn: false,
    };
  },
  computed: {
    currentSchema() {
      return this.isHasRooms ? this.$options.schemaWithRoom : this.$options.schema;
    },
    formIsOpen: {
      get() {
        return this.disabledSendForm ? this.dialogIsOpen : false;
      },
      set(value) {
        this.dialogIsOpen = value;
      },
    },
  },
  watch: {
    initialData: {
      handler(value) {
        const { client: selectedClient, unit } = value;

        if (unit && selectedClient) {
          const cancelSource = client.getCancelToken();
          fetchRooms({ unitId: unit.id, clientId: selectedClient.id }, { cancelToken: cancelSource.token }).then(
            ({ count }) => {
              this.isHasRooms = !!count;
            }
          );
        } else this.isHasRooms = false;
      },
      deep: true,
    },
  },
  mounted() {
    contractService.getDocumentById(this.$route.params.id).then(res => {
      this.documentId = res.document.id;
    });
  },
  methods: {
    openDialog() {
      if (!this.template.fields.length) {
        // eslint-disable-next-line no-alert
        alert('Please draw fields to prepare the document.');
        this.disabledSendForm = true;
        this.dialogIsOpen = false;
      } else {
        this.disabledSendForm = false;
        this.dialogIsOpen = true;
      }
    },
    submit(data) {
      this.loadingBtn = true;
      usersService
        .getUsages({
          client: data.client.id,
          unit: data.room?.id || data.unit.id,
        })
        .then(({ results }) => {
          const requestData = {
            documentId: this.documentId,
            contractorEmail: data.contractorEmail || undefined,
            unitUsageId: results[0].usageId,
            isSingleSide: !data.contractorEmail,
          };

          contractService
            .sendTemplate(requestData)
            .then(() => {
              notificationService.success(this.$t('templates.document_sent'), 2000);
              this.$router.push({ name: AGREEMENTS });
            })
            .finally(() => {
              this.loadingBtn = false;
            });
        })
        .finally(() => {
          this.loadingBtn = false;
        });
    },
  },
  schema,
  schemaWithRoom,
};
</script>
