<template>
  <div>
    <div class="relative preview">
      <img
        :src="previewImage.url"
        :width="previewImage.metadata.width"
        :height="previewImage.metadata.height"
        class="rounded border"
        loading="lazy"
      />
      <!-- <div class="preview__actions group cursor-pointer px-1 py-1" @click="$emit('scroll-to', item)">
        <div v-if="editable" class="d-flex justify-space-between" style="height:100%">
          <div style="width: 26px" />
          <div class="">
            <replace-button
              v-if="withReplaceButton"
              :template-id="template.id"
              :accept-file-types="acceptFileTypes"
              class="opacity-0 group-hover:opacity-100"
              @click.stop
              @success="$emit('replace', { replaceSchemaItem: item, ...$event })"
            />
          </div>
          <div class="d-flex flex-column justify-space-between">
            <div>
              <icon-button
                color="white"
                :width="24"
                class="px-0 not-before"
                style="outline: 1px solid #C7D6FF "
                @click.stop="$emit('remove', item)"
              >
                <v-icon color="primary" size="18">
                  mdi-delete
                </v-icon>
              </icon-button>
            </div>
            <div v-if="withArrows" class="d-flex flex-column align-end">
              <icon-button
                color="white"
                :width="24"
                class="px-0 mb-1 not-before"
                style="outline: 1px solid #C7D6FF "
                @click.stop="$emit('up', item)"
              >
                <v-icon color="primary" size="18">
                  mdi-arrow-up
                </v-icon>
              </icon-button>
              <icon-button
                color="white"
                :width="24"
                class="px-0 not-before"
                style="outline: 1px solid #C7D6FF "
                @click.stop="$emit('down', item)"
              >
                <v-icon color="primary" size="18">
                  mdi-arrow-down
                </v-icon>
              </icon-button>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="flex pb-2 pt-1.5">
      <contenteditable
        :model-value="item.name"
        :icon-size="16"
        :editable="editable"
        style="max-width: 95%"
        class="text-caption mt-2"
        @input="onUpdateName"
      />
    </div>
  </div>
</template>

<script>
// import IconButton from '@/components/IconButton.vue';
import Contenteditable from './Contenteditable.vue';
import Upload from './Upload.vue';
// import ReplaceButton from './Replace.vue';

export default {
  name: 'DocumentPreview',
  components: {
    // IconButton,
    Contenteditable,
    // ReplaceButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    template: {
      type: Object,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    acceptFileTypes: {
      type: String,
      required: false,
      default: 'image/*, application/pdf',
    },
    withReplaceButton: {
      type: Boolean,
      required: true,
      default: true,
    },
    withArrows: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['scroll-to', 'change', 'remove', 'up', 'down', 'replace'],
  computed: {
    previewImage() {
      // eslint-disable-next-line radix
      return [...this.document.previewImages].sort((a, b) => parseInt(a.filename) - parseInt(b.filename))[0];
    },
  },
  methods: {
    upload: Upload.methods.upload,
    onUpdateName(value) {
      this.item.name = value;
      this.$emit('change');
    },
  },
};
</script>
