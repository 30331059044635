<template>
  <div>
    <v-btn
      id="add_document_button"
      tag="label"
      height="203"
      elevation="0"
      color="secondary"
      block
      :for="inputId"
      class="add-document-button primary--text px-0"
      :class="{ 'btn-disabled': isLoading || isProcessing }"
      style="border: 2px dashed var(--v-primary-base) !important;"
    >
      <span class="d-flex flex-column align-center justify-center">
        <v-progress-circular v-if="isLoading || isProcessing" indeterminate size="40" color="primary" class="mb-2" />
        <v-icon v-else x-large color="primary" class="mb-2">mdi-plus</v-icon>
        <span v-if="isLoading">
          {{ $t('templates.uploading') }}
        </span>
        <span v-else-if="isProcessing">
          {{ $t('templates.processing') }}
        </span>
        <span v-else>
          {{ $t('templates.add_page') }}
        </span>
      </span>
    </v-btn>
    <form ref="form" class="hidden">
      <input :id="inputId" ref="input" name="files[]" type="file" :accept="acceptFileTypes" multiple @change="upload" />
    </form>
  </div>
</template>

<script>
// import { UploadIcon, IconInnerShadowTop } from 'vue-tabler-icons';

export default {
  name: 'DocumentsUpload',
  components: {
    // UploadIcon,
    // IconInnerShadowTop,
  },
  inject: ['baseFetch'],
  props: {
    templateId: {
      type: [Number, String],
      required: true,
    },
    acceptFileTypes: {
      type: String,
      required: false,
      default: 'image/*, application/pdf',
    },
  },
  emits: ['success'],
  data() {
    return {
      isLoading: false,
      isProcessing: false,
    };
  },
  computed: {
    inputId() {
      return `el${
        Math.random()
          .toString(32)
          .split('.')[1]
      }`;
    },
  },
  methods: {
    async upload() {
      this.isLoading = true;

      setTimeout(() => {
        this.isLoading = false;
      }, 3000);

      // this.baseFetch(`/templates/${this.templateId}/documents`, {
      //   method: 'POST',
      //   body: new FormData(this.$refs.form),
      // })
      //   .then(resp => {
      //     if (resp.ok) {
      //       resp.json().then(data => {
      //         this.$emit('success', data);
      //         this.$refs.input.value = '';
      //       });
      //     } else if (resp.status === 422) {
      //       resp.json().then(data => {
      //         if (data.error === 'PDF encrypted') {
      //           const formData = new FormData(this.$refs.form);

      //           formData.append('password', prompt('Enter PDF password'));

      //           this.baseFetch(`/templates/${this.templateId}/documents`, {
      //             method: 'POST',
      //             body: formData,
      //           }).then(async resp => {
      //             if (resp.ok) {
      //               this.$emit('success', await resp.json());
      //               this.$refs.input.value = '';
      //             } else {
      //               alert('Wrong password');
      //             }
      //           });
      //         }
      //       });
      //     }
      //   })
      //   .finally(() => {
      //     this.isLoading = false;
      //   });
    },
  },
};
</script>
