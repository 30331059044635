<template>
  <div>
    <div
      class="entity-header d-flex flex-row justify-space-between mb-8 mb-sm-12"
      :class="{ 'flex-column': media.isMobile }"
    >
      <h1 class="text-h5 text-md-h4 font-weight-bold">
        <Contenteditable
          :model-value="template.name"
          :editable="editable"
          class="text-md-h4 text-h5 font-weight-bold"
          :class="{ 'mb-5': media.isMobile }"
          :icon-stroke-width="2.3"
          @input="updateName"
        />
      </h1>

      <div class="d-flex flex-wrap flex-md-nowrap ms-md-10">
        <send-form-modal />
        <v-btn large class="elevation-0 primary--text" color="secondary" @click="finishSave()">
          {{ $t('button.save_template') }}
          <v-icon right color="primary">mdi-content-save-outline</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="d-flex">
      <div class="documents_container" :style="{ display: isBreakpointLg ? 'none' : 'initial' }">
        <document-preview
          v-for="(item, index) in template.schema"
          :key="index"
          :with-arrows="template.schema.length > 1"
          :item="item"
          :document="sortedDocuments[index]"
          :accept-file-types="acceptFileTypes"
          :with-replace-button="withUploadButton"
          :editable="editable"
          :template="template"
          @scroll-to="scrollIntoDocument(item)"
          @remove="onDocumentRemove"
          @replace="onDocumentReplace"
          @up="moveDocument(item, -1)"
          @down="moveDocument(item, 1)"
          @change="save"
        />
        <!-- <div class="sticky bottom-0 py-2 space-y-2" :style="{ backgroundColor }">
          <upload
            v-if="sortedDocuments.length && editable && withUploadButton"
            :accept-file-types="acceptFileTypes"
            :template-id="template.id"
            @success="updateFromUpload"
          />
          <button
            v-if="sortedDocuments.length && editable && withAddPageButton"
            id="add_blank_page_button"
            class="btn btn-outline w-full"
            @click.prevent="addBlankPage"
          >
            <IconInnerShadowTop v-if="isLoadingBlankPage" class="animate-spin w-5 h-5" />
            <PlusIcon v-else class="w-5 h-5" />
            {{ $t('add_blank_page') }}
          </button>
        </div> -->
      </div>
      <div class="pages_container" :class="isBreakpointLg ? 'me-md-4' : 'mx-8'">
        <div ref="documents" class="pr-3.5 pl-0.5">
          <template v-if="!sortedDocuments.length && (withUploadButton || withAddPageButton)">
            <dropzone
              v-if="withUploadButton"
              :template-id="template.id"
              :accept-file-types="acceptFileTypes"
              @success="updateFromUpload"
            />
            <button
              v-if="withAddPageButton"
              id="add_blank_page_button"
              class="btn btn-outline w-full mt-4"
              @click.prevent="addBlankPage"
            >
              <IconInnerShadowTop v-if="isLoadingBlankPage" class="animate-spin w-5 h-5" />
              <PlusIcon v-else class="w-5 h-5" />
              {{ t('add_blank_page') }}
            </button>
          </template>
          <template v-else>
            <div v-for="document in sortedDocuments" :key="document.uuid">
              <document
                ref="documentRefs"
                :areas-index="fieldAreasIndex[document.uuid]"
                :selected-submitter="selectedSubmitter"
                :selected-area-ref="selectedAreaRef"
                :document="document"
                :is-drag="!!dragField"
                :default-fields="[...defaultRequiredFields, ...defaultFields]"
                :allow-draw="!onlyDefinedFields"
                :default-submitters="defaultSubmitters"
                :draw-field="drawField"
                :draw-field-type="drawFieldType"
                :editable="editable"
                :base-url="baseUrl"
                @draw="[onDraw($event), withSelectedFieldType ? '' : (drawFieldType = ''), (showDrawField = false)]"
                @drop-field="onDropfield"
                @remove-area="removeArea"
                @change-selected-area="changeSelectedArea"
              />
              <document-controls
                v-if="isBreakpointLg && editable"
                :with-arrows="template.schema.length > 1"
                :item="template.schema.find(item => item.attachmentUuid === document.uuid)"
                :with-replace-button="withUploadButton"
                :accept-file-types="acceptFileTypes"
                :document="document"
                :template="template"
                class="pb-2 mb-2 border-b border-base-300 border-dashed"
                @remove="onDocumentRemove"
                @replace="onDocumentReplace"
                @up="
                  moveDocument(
                    template.schema.find(item => item.attachmentUuid === document.uuid),
                    -1
                  )
                "
                @down="
                  moveDocument(
                    template.schema.find(item => item.attachmentUuid === document.uuid),
                    1
                  )
                "
                @change="save"
              />
            </div>
            <!-- <div v-if="sortedDocuments.length && isBreakpointLg && editable" class="pb-4 space-y-2">
              <Upload
                v-if="withUploadButton"
                :template-id="template.id"
                :accept-file-types="acceptFileTypes"
                @success="updateFromUpload"
              />
              <button
                v-if="withAddPageButton"
                id="add_blank_page_button"
                class="btn btn-outline w-full mt-4"
                @click.prevent="addBlankPage"
              >
                <IconInnerShadowTop v-if="isLoadingBlankPage" class="animate-spin w-5 h-5" />
                <PlusIcon v-else class="w-5 h-5" />
                {{ t('add_blank_page') }}
              </button>
            </div> -->
          </template>
        </div>
      </div>
      <div v-if="withFieldsList" class="fields_list_container d-none d-md-block">
        <div v-if="showDrawField || drawField" class="sticky inset-0 h-full z-20" :style="{ backgroundColor }">
          <div class="rounded-lg px-5 py-2 text-center" style="background:#FAFAFA">
            <p>
              Draw field on the document
              <!-- {{ $t('draw_field_on_the_document').replace('{field}', (drawField && drawField.name) || '') }} -->
            </p>
            <div>
              <v-btn block color="primary" class="mb-4" @click="clearDrawField">
                Cancel
              </v-btn>
              <a
                v-if="
                  !drawField &&
                    !drawOption &&
                    !['stamp', 'signature', 'initials'].includes((drawField && drawField.type) || drawFieldType)
                "
                href="#"
                class="link block mt-3 text-sm"
                @click.prevent="
                  [
                    addField(drawFieldType),
                    (drawField = null),
                    (drawOption = null),
                    withSelectedFieldType ? '' : (drawFieldType = ''),
                    (showDrawField = false),
                  ]
                "
              >
                Or add field without drawing
                <!-- {{ $t('or_add_field_without_drawing') }} -->
              </a>
            </div>
          </div>
        </div>
        <div v-else>
          <fields
            ref="fields"
            :fields="template.fields"
            :submitters="template.submitters"
            :selected-submitter="selectedSubmitter"
            :with-help="withHelp"
            :default-submitters="defaultSubmitters"
            :draw-field-type="drawFieldType"
            :default-fields="[...defaultRequiredFields, ...defaultFields]"
            :default-required-fields="defaultRequiredFields"
            :field-types="fieldTypes"
            :with-sticky-submitters="withStickySubmitters"
            :only-defined-fields="onlyDefinedFields"
            :editable="editable"
            @add-field="addField"
            @set-draw="[(drawField = $event.field), (drawOption = $event.option)]"
            @set-draw-type="[(drawFieldType = $event), (showDrawField = true)]"
            @set-drag="dragField = $event"
            @change-selected-area="changeSelectedArea"
            @change-submitter="selectedSubmitter = { ...$event }"
            @drag-end="dragField = null"
            @scroll-to-area="scrollToArea"
          />
        </div>
      </div>
    </div>
    <div class="sticky bottom-0" style="padding-bottom:65px">
      <mobile-draw-field
        v-if="drawField && isBreakpointLg"
        :draw-field="drawField"
        :fields="template.fields"
        :submitters="template.submitters"
        :selected-submitter="selectedSubmitter"
        class="d-md-none"
        :editable="editable"
        @cancel="[(drawField = null), (drawOption = null)]"
        @change-submitter="[(selectedSubmitter = { ...$event }), (drawField.submitter_uuid = $event.uuid)]"
      />
      <mobile-fields
        v-if="sortedDocuments.length && !drawField && editable"
        :fields="template.fields"
        :default-fields="[...defaultRequiredFields, ...defaultFields]"
        :default-required-fields="defaultRequiredFields"
        :field-types="fieldTypes"
        :selected-submitter="selectedSubmitter"
        @select="startFieldDraw($event)"
      />
    </div>
  </div>
</template>

<script>
import { v4 } from 'uuid';
import contractService from '@/services/contract';
import notificationService from '@/services/notification';
import { TEMPLATES } from '@/constants/routes';
import SendFormModal from './SendFormModal.vue';

// Components
// import Upload from './Upload.vue';
import DocumentPreview from './Preview.vue';
import Dropzone from './Dropzone.vue';
import Document from './Document.vue';
import DocumentControls from './DocumentControls.vue';
import MobileFields from './MobileFields.vue';
import MobileDrawField from './MobileDrawField.vue';
import Contenteditable from './Contenteditable.vue';
import Fields from './Fields.vue';

export default {
  name: 'TemplateBuilder',

  components: {
    // Upload,
    DocumentPreview,
    Dropzone,
    Document,
    DocumentControls,
    Contenteditable,
    Fields,
    MobileFields,
    MobileDrawField,
    SendFormModal,
  },

  inject: ['media'],

  provide() {
    return {
      template: this.template,
      save: this.save,
      currencies: this.currencies,
      baseFetch: this.baseFetch,
      fieldTypes: this.fieldTypes,
      backgroundColor: this.backgroundColor,
      withPhone: this.withPhone,
      withPayment: this.withPayment,
      isPaymentConnected: this.isPaymentConnected,
      withFormula: this.withFormula,
      withConditions: this.withConditions,
      defaultDrawFieldType: this.defaultDrawFieldType,
      fieldsDragFieldRef: this.fieldsDragFieldRef,
    };
  },

  props: {
    template: {
      type: Object,
      required: true,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: '',
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    withHelp: {
      type: Boolean,
      required: false,
      default: true,
    },
    withAddPageButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    autosave: {
      type: Boolean,
      required: false,
      default: true,
    },
    defaultFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    defaultRequiredFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    withSelectedFieldType: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultDrawFieldType: {
      type: String,
      required: false,
      default: 'text',
    },
    currencies: {
      type: Array,
      required: false,
      default: () => [],
    },
    fieldTypes: {
      type: Array,
      required: false,
      default: () => [],
    },
    defaultSubmitters: {
      type: Array,
      required: false,
      default: () => [],
    },
    acceptFileTypes: {
      type: String,
      required: false,
      default: 'image/*, application/pdf',
    },
    baseUrl: {
      type: String,
      required: false,
      default: '',
    },
    withLogo: {
      type: Boolean,
      required: false,
      default: true,
    },
    onUpload: {
      type: Function,
      required: false,
      default() {
        return () => {};
      },
    },
    onSave: {
      type: Function,
      required: false,
      default() {
        return () => {};
      },
    },
    onChange: {
      type: Function,
      required: false,
      default() {
        return () => {};
      },
    },
    withStickySubmitters: {
      type: Boolean,
      required: false,
      default: true,
    },
    withUploadButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    withTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
    withFieldsList: {
      type: Boolean,
      required: false,
      default: true,
    },
    authenticityToken: {
      type: String,
      required: false,
      default: '',
    },
    withDocumentsList: {
      type: Boolean,
      required: false,
      default: true,
    },
    withPhone: {
      type: Boolean,
      required: false,
      default: false,
    },
    withPayment: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPaymentConnected: {
      type: Boolean,
      required: false,
      default: false,
    },
    withFormula: {
      type: Boolean,
      required: false,
      default: false,
    },
    withConditions: {
      type: Boolean,
      required: false,
      default: false,
    },
    onlyDefinedFields: {
      type: Boolean,
      required: false,
      default: false,
    },
    fetchOptions: {
      type: Object,
      required: false,
      default: () => ({ headers: {} }),
    },
  },

  data() {
    return {
      isLoading: true,
      isBreakpointLg: false,
      isLoadingBlankPage: false,
      isSaving: false,
      selectedSubmitter: null,
      showDrawField: false,
      pendingFieldAttachmentUuids: [],
      drawField: null,
      copiedArea: null,
      drawFieldType: null,
      drawOption: null,
      dragField: null,
      selectedAreaRef: null,
      // eslint-disable-next-line vue/no-reserved-keys
      _saveTimeout: null,
      disabledSendForm: true,
    };
  },

  computed: {
    fieldsDragFieldRef() {
      return null;
    },
    fieldAreasIndex() {
      const areas = {};

      this.template.fields.forEach(f => {
        (f.areas || []).forEach(a => {
          areas[a.attachmentUuid] ||= {};

          // eslint-disable-next-line no-multi-assign
          const acc = (areas[a.attachmentUuid][a.page] ||= []);

          acc.push({ area: a, field: f });
        });
      });

      return areas;
    },
    isAllRequiredFieldsAdded() {
      return !this.defaultRequiredFields?.some(f => {
        return !this.template.fields?.some(field => field.name === f.name);
      });
    },
    selectedField() {
      return this.template.fields.find(f => f.areas?.includes(this.selectedAreaRef));
    },
    sortedDocuments() {
      return this.template.schema.map(item => {
        return this.template.documents.find(doc => doc.uuid === item.attachmentUuid);
      });
    },
  },

  created() {
    if (!this.template.fields?.length && this.template.submitters?.length === 1) {
      if (this.template.submitters[0]?.name === 'First Party') {
        this.template.submitters[0].name = this.$t('first_party');
      }
    }

    const existingSubmittersUuids = this.defaultSubmitters.map(name => {
      return this.template.submitters.find(e => e.name === name)?.uuid;
    });

    this.defaultSubmitters.forEach((name, index) => {
      const submitter = this.template.submitters[index] || {};

      submitter.name = name;

      if (existingSubmittersUuids.filter(Boolean).length) {
        submitter.uuid = existingSubmittersUuids[index] || submitter.uuid || v4();
      } else {
        submitter.uuid ||= v4();
      }
    });

    this.selectedSubmitter = { ...this.template.submitters[0] };
  },

  mounted() {
    this.undoStack = [JSON.stringify(this.template)];
    this.redoStack = [];

    this.$nextTick(() => {
      this.onWindowResize();
    });

    document.addEventListener('keyup', this.onKeyUp);
    window.addEventListener('keydown', this.onKeyDown);

    window.addEventListener('resize', this.onWindowResize);

    this.$nextTick(() => {
      if (document.location.search?.includes('stripe_connect_success')) {
        document
          .querySelector('form[action="/auth/stripe_connect"]')
          ?.closest('.dropdown')
          ?.querySelector('label')
          ?.focus();
      }
    });

    this.template.schema.forEach(item => {
      if (item.pending_fields) {
        this.pendingFieldAttachmentUuids.push(item.attachmentUuid);
      }
    });
  },

  unmounted() {
    document.removeEventListener('keyup', this.onKeyUp);
    window.removeEventListener('keydown', this.onKeyDown);

    window.removeEventListener('resize', this.onWindowResize);
  },

  // beforeUpdate() {
  //   this.$refs.documentRefs = [];
  // },

  methods: {
    getTemplate() {
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
    updateName(value) {
      this.template.name = value;

      this.save();
    },
    sendTemplate() {},
    removePendingFields() {
      this.template.fields = this.template.fields.filter(f => {
        return this.template.schema.find(item => item.attachmentUuid === f.attachmentUuid && item.pending_fields);
      });

      this.save();
    },
    addField(type, area = null) {
      const field = {
        name: '',
        uuid: v4(),
        required: type !== 'checkbox',
        areas: area ? [area] : [],
        submitterUuid: this.selectedSubmitter.uuid,
        type,
      };

      if (['select', 'multiple', 'radio'].includes(type)) {
        field.options = [{ value: '', uuid: v4() }];
      }

      if (type === 'stamp') {
        field.readonly = true;
      }

      if (type === 'date') {
        field.preferences = {
          format:
            Intl.DateTimeFormat()
              .resolvedOptions()
              .locale.endsWith('-US') ||
            new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' })
              .format(new Date())
              .match(/\s(?:CST|CDT|PST|PDT|EST|EDT)$/)
              ? 'MM/DD/YYYY'
              : 'DD/MM/YYYY',
        };
      }

      this.template.fields.push(field);

      this.save();
    },
    startFieldDraw({ name, type }) {
      const existingField = this.template.fields?.find(
        f => f.submitterUuid === this.selectedSubmitter.uuid && name && name === f.name
      );

      if (existingField) {
        this.drawField = existingField;
      } else {
        const field = {
          name: name || '',
          uuid: v4(),
          required: type !== 'checkbox',
          areas: [],
          submitterUuid: this.selectedSubmitter.uuid,
          type,
        };

        if (['select', 'multiple', 'radio'].includes(type)) {
          field.options = [{ value: '', uuid: v4() }];
        }

        if (type === 'stamp') {
          field.readonly = true;
        }

        if (type === 'date') {
          field.preferences = {
            format:
              Intl.DateTimeFormat()
                .resolvedOptions()
                .locale.endsWith('-US') ||
              new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' })
                .format(new Date())
                .match(/\s(?:CST|CDT|PST|PDT|EST|EDT)$/)
                ? 'MM/DD/YYYY'
                : 'DD/MM/YYYY',
          };
        }

        this.drawField = field;
      }

      this.drawOption = null;
    },
    undo() {
      if (this.undoStack.length > 1) {
        this.undoStack.pop();
        const stringData = this.undoStack[this.undoStack.length - 1];
        const currentStringData = JSON.stringify(this.template);

        if (stringData && stringData !== currentStringData) {
          this.redoStack.push(currentStringData);

          Object.assign(this.template, JSON.parse(stringData));

          this.save();
        }
      }
    },
    redo() {
      const stringData = this.redoStack.pop();
      this.lastRedoData = stringData;
      const currentStringData = JSON.stringify(this.template);

      if (stringData && stringData !== currentStringData) {
        if (this.undoStack[this.undoStack.length - 1] !== currentStringData) {
          this.undoStack.push(currentStringData);
        }

        Object.assign(this.template, JSON.parse(stringData));

        this.save();
      }
    },
    onWindowResize() {
      const breakpointLg = 1200;
      this.isBreakpointLg = this.$el.getRootNode().querySelector('main.v-main').offsetWidth < breakpointLg;
    },
    scrollIntoDocument(item) {
      const ref = this.$refs.documentRefs.find(e => e.document.uuid === item.attachmentUuid);
      ref.$el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },
    clearDrawField() {
      this.drawField = null;
      this.drawOption = null;
      this.showDrawField = false;
      if (!this.withSelectedFieldType) {
        this.drawFieldType = '';
      }
    },
    onKeyUp(e) {
      if (e.code === 'Escape') {
        this.clearDrawField();

        this.selectedAreaRef = null;
      }

      if (
        this.editable &&
        ['Backspace', 'Delete'].includes(e.key) &&
        this.selectedAreaRef &&
        document.activeElement === document.body
      ) {
        this.removeArea(this.selectedAreaRef);

        this.selectedAreaRef = null;
      }
    },
    onKeyDown(event) {
      if ((event.metaKey && event.shiftKey && event.key === 'z') || (event.ctrlKey && event.key === 'Z')) {
        event.stopImmediatePropagation();
        event.preventDefault();

        this.redo();
      } else if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
        event.stopImmediatePropagation();
        event.preventDefault();

        this.undo();
      } else if ((event.ctrlKey || event.metaKey) && event.key === 'c' && document.activeElement === document.body) {
        event.preventDefault();

        this.copiedArea = this.selectedAreaRef;
      } else if (
        (event.ctrlKey || event.metaKey) &&
        event.key === 'v' &&
        this.copiedArea &&
        document.activeElement === document.body
      ) {
        event.preventDefault();

        this.pasteField();
      } else if (
        this.selectedAreaRef &&
        ['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'].includes(event.key) &&
        document.activeElement === document.body
      ) {
        event.preventDefault();

        this.handleAreaArrows(event);
      }
    },
    handleAreaArrows(event) {
      if (!this.editable) {
        return;
      }

      const area = this.selectedAreaRef;
      const documentRef = this.$refs.documentRefs.find(e => e.document.uuid === area.attachmentUuid);
      const page = documentRef.pageRefs[area.page].$refs.image;
      const rect = page.getBoundingClientRect();
      const diff = event.shiftKey ? 5.0 : 1.0;

      if (event.key === 'ArrowRight' && event.altKey) {
        area.w = Math.min(Math.max(area.w + diff / rect.width, 0), 1 - area.x);
      } else if (event.key === 'ArrowLeft' && event.altKey) {
        area.w = Math.min(Math.max(area.w - diff / rect.width, 0), 1 - area.x);
      } else if (event.key === 'ArrowUp' && event.altKey) {
        area.h = Math.min(Math.max(area.h - diff / rect.height, 0), 1 - area.y);
      } else if (event.key === 'ArrowDown' && event.altKey) {
        area.h = Math.min(Math.max(area.h + diff / rect.height, 0), 1 - area.y);
      } else if (event.key === 'ArrowRight') {
        area.x = Math.min(Math.max(area.x + diff / rect.width, 0), 1 - area.w);
      } else if (event.key === 'ArrowLeft') {
        area.x = Math.min(Math.max(area.x - diff / rect.width, 0), 1 - area.w);
      } else if (event.key === 'ArrowUp') {
        area.y = Math.min(Math.max(area.y - diff / rect.height, 0), 1 - area.h);
      } else if (event.key === 'ArrowDown') {
        area.y = Math.min(Math.max(area.y + diff / rect.height, 0), 1 - area.h);
      }

      this.debouncedSave();
    },
    debouncedSave() {
      // eslint-disable-next-line no-underscore-dangle
      clearTimeout(this._saveTimeout);

      // eslint-disable-next-line no-underscore-dangle
      this._saveTimeout = setTimeout(() => {
        this.save();
      }, 700);
    },
    removeArea(area) {
      const field = this.template.fields.find(f => f.areas?.includes(area));

      field.areas.splice(field.areas.indexOf(area), 1);

      if (!field.areas.length) {
        this.template.fields.splice(this.template.fields.indexOf(field), 1);
      }

      this.save();
    },
    pasteField() {
      const field = this.template.fields.find(f => f.areas?.includes(this.copiedArea));
      const currentArea = this.selectedAreaRef || this.copiedArea;

      if (field && currentArea) {
        const area = {
          ...JSON.parse(JSON.stringify(this.copiedArea)),
          attachmentUuid: currentArea.attachmentUuid,
          page: currentArea.page,
          x: currentArea.x,
          y: currentArea.y + currentArea.h * 1.3,
        };

        if (['radio', 'multiple'].includes(field.type)) {
          this.copiedArea.option_uuid ||= field.options[0].uuid;
          area.option_uuid = v4();

          field.options.push({ uuid: area.option_uuid });

          field.areas.push(area);
        } else {
          this.template.fields.push({
            ...JSON.parse(JSON.stringify(field)),
            uuid: v4(),
            areas: [area],
          });
        }

        this.selectedAreaRef = area;

        this.save();
      }
    },
    pushUndo() {
      const stringData = JSON.stringify(this.template);

      if (this.undoStack[this.undoStack.length - 1] !== stringData) {
        this.undoStack.push(stringData);

        if (this.lastRedoData !== stringData) {
          this.redoStack = [];
        }
      }
    },
    setDefaultAreaSize(area, type) {
      const documentRef = this.$refs.documentRefs.find(e => e.document.uuid === area.attachmentUuid);
      const pageMask = documentRef.pageRefs[area.page].$refs.mask;

      if (type === 'checkbox') {
        // eslint-disable-next-line no-param-reassign
        area.w = pageMask.clientWidth / 30 / pageMask.clientWidth;
        // eslint-disable-next-line no-param-reassign
        area.h = (pageMask.clientWidth / 30 / pageMask.clientWidth) * (pageMask.clientWidth / pageMask.clientHeight);
      } else if (type === 'image') {
        // eslint-disable-next-line no-param-reassign
        area.w = pageMask.clientWidth / 5 / pageMask.clientWidth;
        // eslint-disable-next-line no-param-reassign
        area.h = (pageMask.clientWidth / 5 / pageMask.clientWidth) * (pageMask.clientWidth / pageMask.clientHeight);
      } else if (type === 'signature' || type === 'stamp') {
        // eslint-disable-next-line no-param-reassign
        area.w = pageMask.clientWidth / 5 / pageMask.clientWidth;
        // eslint-disable-next-line no-param-reassign
        area.h =
          ((pageMask.clientWidth / 5 / pageMask.clientWidth) * (pageMask.clientWidth / pageMask.clientHeight)) / 2;
      } else if (type === 'initials') {
        // eslint-disable-next-line no-param-reassign
        area.w = pageMask.clientWidth / 10 / pageMask.clientWidth;
        // eslint-disable-next-line no-param-reassign
        area.h = pageMask.clientWidth / 35 / pageMask.clientWidth;
      } else {
        // eslint-disable-next-line no-param-reassign
        area.w = pageMask.clientWidth / 5 / pageMask.clientWidth;
        // eslint-disable-next-line no-param-reassign
        area.h = pageMask.clientWidth / 35 / pageMask.clientWidth;
      }
    },
    onDraw(area) {
      if (this.drawField) {
        if (this.drawOption) {
          const areaWithoutOption = this.drawField.areas?.find(a => !a.option_uuid);
          if (areaWithoutOption && !this.drawField.areas.find(a => a.option_uuid === this.drawField.options[0].uuid)) {
            areaWithoutOption.option_uuid = this.drawField.options[0].uuid;
          }

          // eslint-disable-next-line no-param-reassign
          area.option_uuid = this.drawOption.uuid;
        }

        if (area.w === 0 || area.h === 0) {
          const previousArea = this.drawField.areas?.[this.drawField.areas.length - 1];

          if (this.selectedField?.type === this.drawField.type) {
            // eslint-disable-next-line no-param-reassign
            area.w = this.selectedAreaRef.w;
            // eslint-disable-next-line no-param-reassign
            area.h = this.selectedAreaRef.h;
          } else if (previousArea) {
            // eslint-disable-next-line no-param-reassign
            area.w = previousArea.w;
            // eslint-disable-next-line no-param-reassign
            area.h = previousArea.h;
          } else {
            this.setDefaultAreaSize(area, this.drawOption ? 'checkbox' : this.drawField?.type);
          }

          // eslint-disable-next-line no-param-reassign
          area.x -= area.w / 2;
          // eslint-disable-next-line no-param-reassign
          area.y -= area.h / 2;
        }

        this.drawField.areas ||= [];

        const insertBeforeAreaIndex = this.drawField.areas.findIndex(a => {
          return a.attachmentUuid === area.attachmentUuid && a.page > area.page;
        });

        if (insertBeforeAreaIndex !== -1) {
          this.drawField.areas.splice(insertBeforeAreaIndex, 0, area);
        } else {
          this.drawField.areas.push(area);
        }

        if (this.template.fields.indexOf(this.drawField) === -1) {
          this.template.fields.push(this.drawField);
        }

        this.drawField = null;
        this.drawOption = null;

        this.selectedAreaRef = area;

        this.save();
      } else {
        const documentRef = this.$refs.documentRefs.find(e => e.document.uuid === area.attachmentUuid);
        const pageMask = documentRef.$refs.pageRefs[area.page].$refs.mask;

        let type = pageMask.clientWidth * area.w < 35 ? 'checkbox' : 'text';

        if (this.drawFieldType) {
          type = this.drawFieldType;
        } else if (this.defaultDrawFieldType && this.defaultDrawFieldType !== 'text') {
          type = this.defaultDrawFieldType;
        } else if (this.fieldTypes.length !== 0 && !this.fieldTypes.includes(type)) {
          type = [...this.fieldTypes[0]];
        }

        if (
          type === 'checkbox' &&
          !this.drawFieldType &&
          (this.template.fields[this.template.fields.length - 1]?.type === 'checkbox' || area.w)
        ) {
          const previousField = [...this.template.fields].reverse().find(f => f.type === type);
          const previousArea = previousField?.areas?.[previousField.areas.length - 1];

          if (previousArea || area.w) {
            const areaW = previousArea?.w || 30 / pageMask.clientWidth;
            const areaH = previousArea?.h || 30 / pageMask.clientHeight;

            if (pageMask.clientWidth * area.w < 5) {
              // eslint-disable-next-line no-param-reassign
              area.x -= areaW / 2;
              // eslint-disable-next-line no-param-reassign
              area.y -= areaH / 2;
            }

            // eslint-disable-next-line no-param-reassign
            area.w = areaW;
            // eslint-disable-next-line no-param-reassign
            area.h = areaH;
          }
        }

        if (this.drawFieldType && (area.w === 0 || area.h === 0)) {
          if (this.selectedField?.type === this.drawFieldType) {
            // eslint-disable-next-line no-param-reassign
            area.w = this.selectedAreaRef.w;
            // eslint-disable-next-line no-param-reassign
            area.h = this.selectedAreaRef.h;
          } else {
            this.setDefaultAreaSize(area, this.drawFieldType);
          }

          // eslint-disable-next-line no-param-reassign
          area.x -= area.w / 2;
          // eslint-disable-next-line no-param-reassign
          area.y -= area.h / 2;
        }

        if (area.w) {
          this.addField(type, area);

          this.selectedAreaRef = area;
        }
      }
    },
    onDropfield(area) {
      const field = this.fieldsDragFieldRef || {
        name: '',
        uuid: v4(),
        submitterUuid: this.selectedSubmitter.uuid,
        required: this.dragField.type !== 'checkbox',
        ...this.dragField,
      };

      if (!this.fieldsDragFieldRef) {
        if (['select', 'multiple', 'radio'].includes(field.type)) {
          field.options = [{ value: '', uuid: v4() }];
        }

        if (field.type === 'stamp') {
          field.readonly = true;
        }

        if (field.type === 'date') {
          field.preferences = {
            format:
              Intl.DateTimeFormat()
                .resolvedOptions()
                .locale.endsWith('-US') ||
              new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' })
                .format(new Date())
                .match(/\s(?:CST|CDT|PST|PDT|EST|EDT)$/)
                ? 'MM/DD/YYYY'
                : 'DD/MM/YYYY',
          };
        }
      }

      const fieldArea = {
        x: (area.x - 6) / area.maskW,
        y: area.y / area.maskH,
        page: area.page,
        attachmentUuid: area.attachmentUuid,
      };

      const previousField = [...this.template.fields].reverse().find(f => f.type === field.type);

      let baseArea;

      if (this.selectedField?.type === field.type) {
        baseArea = this.selectedAreaRef;
      } else if (previousField?.areas?.length) {
        baseArea = previousField.areas[previousField.areas.length - 1];
      } else if (['checkbox'].includes(field.type)) {
        baseArea = {
          w: area.maskW / 30 / area.maskW,
          h: (area.maskW / 30 / area.maskW) * (area.maskW / area.maskH),
        };
      } else if (field.type === 'image') {
        baseArea = {
          w: area.maskW / 5 / area.maskW,
          h: (area.maskW / 5 / area.maskW) * (area.maskW / area.maskH),
        };
      } else if (field.type === 'signature' || field.type === 'stamp') {
        baseArea = {
          w: area.maskW / 5 / area.maskW,
          h: ((area.maskW / 5 / area.maskW) * (area.maskW / area.maskH)) / 2,
        };
      } else if (field.type === 'initials') {
        baseArea = {
          w: area.maskW / 10 / area.maskW,
          h: area.maskW / 35 / area.maskW,
        };
      } else {
        baseArea = {
          w: area.maskW / 5 / area.maskW,
          h: area.maskW / 35 / area.maskW,
        };
      }

      fieldArea.w = baseArea.w;
      fieldArea.h = baseArea.h;
      fieldArea.y -= baseArea.h / 2;

      if (field.type === 'cells') {
        fieldArea.cellW = baseArea.cellW || baseArea.w / 5;
      }

      field.areas ||= [];

      const lastArea = field.areas[field.areas.length - 1];

      if (lastArea) {
        fieldArea.x -= lastArea.w / 2;
        fieldArea.w = lastArea.w;
        fieldArea.h = lastArea.h;
      }

      field.areas.push(fieldArea);

      this.selectedAreaRef = fieldArea;

      if (this.template.fields.indexOf(field) === -1) {
        this.template.fields.push(field);
      }

      this.save();

      document.activeElement?.blur();
    },
    addBlankPage() {
      this.isLoadingBlankPage = true;

      const canvas = document.createElement('canvas');

      canvas.width = 816;
      canvas.height = 1056;

      const ctx = canvas.getContext('2d');

      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      canvas.toBlob(blob => {
        const file = new File([blob], `Page ${this.template.schema.length + 1}.png`, {
          type: blob.type,
          lastModified: Date.now(),
        });

        const formData = new FormData();
        formData.append('files[]', file);

        this.baseFetch(`/templates/${this.template.id}/documents`, {
          method: 'POST',
          body: formData,
        })
          .then(async resp => {
            this.updateFromUpload(await resp.json());
          })
          .finally(() => {
            this.isLoadingBlankPage = false;
          });
      }, 'image/png');
    },
    updateFromUpload(data) {
      this.template.schema.push(...data.schema);
      this.template.documents.push(...data.documents);

      if (data.fields) {
        this.template.fields = data.fields;
      }

      if (data.submitters) {
        this.template.submitters = data.submitters;

        if (!this.template.submitters.find(s => s.uuid === this.selectedSubmitter?.uuid)) {
          this.selectedSubmitter = { ...this.template.submitters[0] };
        }
      }

      this.$nextTick(() => {
        this.$refs.previews.scrollTop = this.$refs.previews.scrollHeight;

        this.scrollIntoDocument(data.schema[0]);
      });

      if (this.template.name === 'New Document') {
        this.template.name = this.template.schema[0].name;
      }

      if (this.onUpload) {
        this.onUpload(this.template);
      }

      this.save();

      data.documents.forEach(attachment => {
        if (attachment.metadata?.pdf?.fields?.length) {
          this.pendingFieldAttachmentUuids.push(attachment.uuid);

          attachment.metadata.pdf.fields.forEach(field => {
            // eslint-disable-next-line no-param-reassign
            field.submitterUuid = this.selectedSubmitter.uuid;

            this.template.fields.push(field);
          });
        }
      });
    },
    onDocumentRemove(item) {
      // eslint-disable-next-line no-alert
      if (window.confirm(this.$t('are_you_sure'))) {
        this.template.schema.splice(this.template.schema.indexOf(item), 1);
      }

      const removedFieldUuids = [];

      this.template.fields.forEach(field => {
        [...(field.areas || [])].forEach(area => {
          if (area.attachmentUuid === item.attachmentUuid) {
            field.areas.splice(field.areas.indexOf(area), 1);

            removedFieldUuids.push(field.uuid);
          }
        });
      });

      this.template.fields = this.template.fields.filter(f => !removedFieldUuids.includes(f.uuid) || f.areas?.length);

      this.save();
    },
    onDocumentReplace(data) {
      const { replaceSchemaItem, schema, documents } = data;

      this.template.schema.splice(this.template.schema.indexOf(replaceSchemaItem), 1, schema[0]);
      this.template.documents.push(...documents);

      if (data.fields) {
        this.template.fields = data.fields;

        const removedFieldUuids = [];

        this.template.fields.forEach(field => {
          [...(field.areas || [])].forEach(area => {
            if (area.attachmentUuid === replaceSchemaItem.attachmentUuid) {
              field.areas.splice(field.areas.indexOf(area), 1);

              removedFieldUuids.push(field.uuid);
            }
          });
        });

        this.template.fields = this.template.fields.filter(f => !removedFieldUuids.includes(f.uuid) || f.areas?.length);
      }

      if (data.submitters) {
        this.template.submitters = data.submitters;

        if (!this.template.submitters.find(s => s.uuid === this.selectedSubmitter?.uuid)) {
          this.selectedSubmitter = [...this.template.submitters[0]];
        }
      }

      this.template.fields.forEach(field => {
        (field.areas || []).forEach(area => {
          if (area.attachmentUuid === replaceSchemaItem.attachmentUuid) {
            // eslint-disable-next-line no-param-reassign
            area.attachmentUuid = schema[0].attachmentUuid;
          }
        });
      });

      if (this.onUpload) {
        this.onUpload(this.template);
      }

      this.save();
    },
    moveDocument(item, direction) {
      const currentIndex = this.template.schema.indexOf(item);

      this.template.schema.splice(currentIndex, 1);

      if (currentIndex + direction > this.template.schema.length) {
        this.template.schema.unshift(item);
      } else if (currentIndex + direction < 0) {
        this.template.schema.push(item);
      } else {
        this.template.schema.splice(currentIndex + direction, 0, item);
      }

      this.save();
    },
    maybeShowErrorTemplateAlert() {
      // if (!this.isAllRequiredFieldsAdded) {
      //   e.preventDefault();
      //   const fields = this.defaultRequiredFields?.filter(f => {
      //     return !this.template.fields?.some(field => field.name === f.name);
      //   });
      //   if (fields?.length) {
      //     // eslint-disable-next-line no-alert
      //     alert(`${this.$t('add_all_required_fields_to_continue')}: ${fields.map(f => f.name).join(', ')}`);
      //     return;
      //   }
      // }
      // if (!this.template.fields.length) {
      //   e.preventDefault();
      //   // eslint-disable-next-line no-alert
      //   alert('Please draw fields to prepare the document.');
      // } else {
      // const submitterWithoutFields = this.template.submitters.find(
      //   submitter => !this.template.fields.some(f => f.submitterUuid === submitter.uuid)
      // );
      // if (submitterWithoutFields) {
      //   e.preventDefault();
      //   // eslint-disable-next-line no-alert
      //   alert(
      //     `Please add fields for the ${submitterWithoutFields.name}. Or, remove the ${submitterWithoutFields.name} if not needed.`
      //   );
      // }
      // }
    },
    onSaveClick() {
      if (!this.isAllRequiredFieldsAdded) {
        const fields = this.defaultRequiredFields?.filter(f => {
          return !this.template.fields?.some(field => field.name === f.name);
        });

        if (fields?.length) {
          // eslint-disable-next-line no-alert
          return alert(`${this.$t('add_all_required_fields_to_continue')}: ${fields.map(f => f.name).join(', ')}`);
        }
      }

      if (!this.template.fields.length) {
        // eslint-disable-next-line no-alert
        alert('Please draw fields to prepare the document.');
      } else {
        const submitterWithoutFields = this.template.submitters.find(
          submitter => !this.template.fields.some(f => f.submitterUuid === submitter.uuid)
        );

        if (submitterWithoutFields) {
          // eslint-disable-next-line no-alert
          alert(
            `Please add fields for the ${submitterWithoutFields.name}. Or, remove the ${submitterWithoutFields.name} if not needed.`
          );
        } else {
          this.isSaving = true;

          this.save();
          //   .then(() => {
          //     window.Turbo.visit(`/templates/${this.template.id}`);
          //   })
          //   .finally(() => {
          //     this.isSaving = false;
          //   });
        }
      }
      return null;
    },
    scrollToArea(area) {
      // const documentRef = this.$refs.documentRefs.find(a => a.document.uuid === area.attachmentUuid);

      // documentRef.scrollToArea(area);

      this.selectedAreaRef = area;
    },
    // baseFetch(path, options = {}) {
    //   return fetch(this.baseUrl + path, {
    //     ...options,
    //     headers: {
    //       'X-CSRF-Token': this.authenticityToken,
    //       ...this.fetchOptions.headers,
    //       ...options.headers,
    //     },
    //   });
    // },
    async save() {
      if (!this.template) return;

      this.pendingFieldAttachmentUuids = [];

      if (this.onChange) {
        this.onChange(this.template);
      }

      this.pushUndo();

      const data = {
        name: this.template.name,
        schema: this.template.schema,
        fields: this.template.fields,
        submitters: this.template.submitters,
      };

      await contractService.saveTemplate(data, this.$route.params.id);
    },

    finishSave() {
      this.save().then(() => {
        notificationService.success(this.$t('templates.template_saved'), 2000);
        this.$router.push({ name: TEMPLATES });
      });
    },
    changeSelectedArea(selectedArea) {
      this.selectedAreaRef = selectedArea;
    },
  },
};
</script>
