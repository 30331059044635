<template>
  <div>
    <page
      v-for="(image, index) in sortedPreviewImages"
      :key="image.id"
      ref="pageRefs"
      :number="index"
      :editable="editable"
      :areas="areasIndex[index]"
      :selected-area-ref="selectedAreaRef"
      :allow-draw="allowDraw"
      :is-drag="isDrag"
      :default-fields="defaultFields"
      :default-submitters="defaultSubmitters"
      :draw-field="drawField"
      :draw-field-type="drawFieldType"
      :selected-submitter="selectedSubmitter"
      :image="image"
      @drop-field="$emit('drop-field', { ...$event, attachmentUuid: document.uuid })"
      @remove-area="$emit('remove-area', $event)"
      @draw="$emit('draw', { ...$event, attachmentUuid: document.uuid })"
      @change-selected-area="$emit('change-selected-area', $event)"
    />
  </div>
</template>
<script>
import Page from './Page.vue';

export default {
  name: 'TemplateDocument',
  components: {
    Page,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    areasIndex: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    defaultFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    drawFieldType: {
      type: String,
      required: false,
      default: '',
    },
    defaultSubmitters: {
      type: Array,
      required: false,
      default: () => [],
    },
    allowDraw: {
      type: Boolean,
      required: false,
      default: true,
    },
    selectedSubmitter: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    drawField: {
      type: Object,
      required: false,
      default: null,
    },
    baseUrl: {
      type: String,
      required: false,
      default: '',
    },
    isDrag: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedAreaRef: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ['draw', 'drop-field', 'remove-area'],
  data() {
    return {};
  },
  computed: {
    basePreviewUrl() {
      if (this.baseUrl) {
        return new URL(this.baseUrl).origin;
      }
      return '';
    },
    numberOfPages() {
      return this.document.metadata?.pdf?.number_of_pages || this.document.previewImages.length;
    },
    sortedPreviewImages() {
      const lazyloadMetadata = this.document.previewImages[this.document.previewImages.length - 1].metadata;

      return [...Array(this.numberOfPages).keys()].map(i => {
        return (
          this.previewImagesIndex[i] || {
            metadata: lazyloadMetadata,
            id: Math.random().toString(),
            url: `${this.basePreviewUrl}/preview/${this.document.signed_uuid || this.document.uuid}/${i}.jpg`,
          }
        );
      });
    },
    previewImagesIndex() {
      return this.document.previewImages.reduce((acc, e) => {
        acc[parseInt(e.filename, 10)] = e;

        return acc;
      }, {});
    },
  },
  // beforeUpdate() {
  //   this.pageRefs = [];
  // },
  methods: {
    // scrollToArea(area) {
    //   this.$nextTick(() => {
    //     this.$refs.pageRefs[area.page].areaRefs
    //       .find(e => e.area === area)
    //       .$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    //   });
    // },
    // setPageRefs(el) {
    //   if (el) {
    //     this.$refs.pageRefs.push(el);
    //   }
    // },
  },
};
</script>
