<template>
  <div class="d-flex align-center contenteditable">
    <span
      ref="contenteditable"
      dir="auto"
      :contenteditable="editable"
      style="min-width:2px;word-break: break-all;"
      class="template-name outline-none px-1"
      spellcheck="false"
      @paste.prevent="onPaste"
      @keydown.enter.prevent="blurContenteditable"
      @focus="$emit('focus', $event)"
      @blur="onBlur"
    >
      {{ value }}
    </span>
    <v-icon
      color="primary"
      class="ms-2 edit-template-name"
      :size="iconSize"
      @click="[focusContenteditable(), selectOnEditClick && selectContent()]"
    >
      mdi-pencil
    </v-icon>
  </div>
</template>

<script>
export default {
  name: 'ContenteditableField',

  components: {},

  props: {
    modelValue: {
      type: String,
      required: false,
      default: '',
    },
    iconInline: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconSize: {
      type: Number,
      required: false,
      default: 30,
    },
    withRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectOnEditClick: {
      type: Boolean,
      required: false,
      default: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    iconStrokeWidth: {
      type: Number,
      required: false,
      default: 2,
    },
  },
  emits: ['input', 'focus', 'blur'],
  data() {
    return {
      value: '',
    };
  },
  watch: {
    modelValue: {
      handler(value) {
        this.value = value;
      },
      immediate: true,
    },
  },
  methods: {
    onPaste(e) {
      const text = (e.clipboardData || window.clipboardData).getData('text/plain');

      const selection = window.getSelection();

      if (selection.rangeCount) {
        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(text));
        selection.collapseToEnd();
      }
    },
    selectContent() {
      const el = this.$refs.contenteditable;

      const range = document.createRange();

      range.selectNodeContents(el);

      const sel = window.getSelection();

      sel.removeAllRanges();

      sel.addRange(range);
    },
    onBlur(e) {
      setTimeout(() => {
        this.value = this.$refs.contenteditable.innerText.trim() || this.modelValue;
        this.$emit('input', this.value);
        this.$emit('blur', e);
      }, 1);
    },
    focusContenteditable() {
      this.$refs.contenteditable.focus();
    },
    blurContenteditable() {
      this.$refs.contenteditable.blur();
    },
  },
};
</script>
