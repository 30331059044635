<template>
  <div class="field group mb-1">
    <div class="relative">
      <div class="d-flex align-center justify-space-between relative py-4 ps-5 pe-2">
        <!-- Для скролла к странице -->
        <!-- <div class="absolute top-0 bottom-0 right-0 left-0 cursor-pointer" @click="scrollToFirstArea" /> -->
        <div class="d-flex align-center">
          <FieldType
            v-model="field.type"
            color="black"
            :editable="editable && !defaultField"
            :button-width="20"
            :menu-classes="'mt-1.5'"
            :menu-style="{ backgroundColor: dropdownBgColor }"
            @input="[maybeUpdateOptions(), save()]"
            @click="scrollToFirstArea"
          />
          <Contenteditable
            ref="name"
            :model-value="(defaultField ? field.title || field.name : field.name) || defaultName"
            :editable="editable && !defaultField"
            :icon-inline="true"
            :icon-size="18"
            :icon-stroke-width="1.6"
            class="ms-1"
            @focus="[onNameFocus(), scrollToFirstArea()]"
            @blur="onNameBlur"
          />
        </div>
        <div v-if="editable" class="d-flex align-center">
          <v-btn
            v-if="field && field.areas && !field.areas.length"
            title="Draw"
            class="ms-1"
            icon
            x-small
            @click="$emit('set-draw', { field })"
          >
            <NewSectionIcon :width="18" :stroke-width="1.6" />
          </v-btn>
          <button
            v-if="field.preferences && field.preferences.formula"
            class="relative cursor-pointer text-transparent group-hover:text-base-content"
            :title="$t('formula')"
            @click="isShowFormulaModal = true"
          >
            <MathFunctionIcon :width="18" :stroke-width="1.6" />
          </button>
          <button
            v-if="field.conditions && field.conditions.length"
            class="relative cursor-pointer text-transparent group-hover:text-base-content"
            :title="$t('condition')"
            @click="isShowConditionsModal = true"
          >
            <IconRouteAltLeft :width="18" :stroke-width="1.6" />
          </button>
          <v-menu
            v-model="showSettingsMenu"
            offset-y
            left
            style="background: white"
            :close-on-content-click="false"
            max-width="243"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                ref="settingsButton"
                :title="$t('settings')"
                class="ms-1"
                icon
                x-small
                tabindex="0"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="#A2A2A2" size="20">mdi-cog-outline</v-icon>
              </v-btn>
            </template>
            <v-card elevation="0" class="px-4 py-2">
              <field-settings
                :field="field"
                :default-field="defaultField"
                :editable="editable"
                background-color="white"
                @click-formula="isShowFormulaModal = true"
                @click-description="isShowDescriptionModal = true"
                @click-condition="isShowConditionsModal = true"
                @set-draw="$emit('set-draw', $event)"
                @scroll-to="[$emit('change-selected-area', $event), $emit('scroll-to', $event)]"
              />
            </v-card>
          </v-menu>
          <v-btn x-small icon color="black" class="ms-1" @click="$emit('remove', field)">
            <v-icon color="#A2A2A2" size="20">mdi-delete</v-icon>
          </v-btn>
        </div>
      </div>
      <div
        v-if="field.options"
        ref="options"
        class="border-t border-base-300 mx-2 pt-2 space-y-1.5"
        draggable="true"
        @dragstart.prevent.stop
      >
        <div v-for="(option, index) in field.options" :key="option.uuid" class="d-flex align-center">
          <span class="text-sm w-3.5 me-2 pb-4"> {{ index + 1 }}. </span>
          <div
            v-if="
              editable &&
                ['radio', 'multiple'].includes(field.type) &&
                (index > 0 || field.areas.find(a => a.option_uuid) || !field.areas.length) &&
                !field.areas.find(a => a.option_uuid === option.uuid)
            "
            class="align-center d-flex w-full"
          >
            <v-text-field
              v-model="option.value"
              type="text"
              :label="`Option ${index + 1}`"
              required
              class="py-0"
              @blur="save"
            ></v-text-field>
            <button title="Draw" tabindex="-1" @click.prevent="$emit('set-draw', { field, option })">
              <component :is="$options.NewSectionIcon" />
            </button>
          </div>
          <v-text-field
            v-else
            v-model="option.value"
            type="text"
            :label="`Option ${index + 1}`"
            :readonly="!editable"
            required
            class="py-0"
            @focus="maybeFocusOnOptionArea(option)"
            @blur="save"
          ></v-text-field>
          <!-- <input
            v-else
            v-model="option.value"
            class="w-full input input-primary input-xs text-sm bg-transparent"
            :placeholder="`${$t('option')} ${index + 1}`"
            type="text"
            :readonly="!editable"
            required
            dir="auto"
            @focus="maybeFocusOnOptionArea(option)"
            @blur="save"
          />
          <button v-if="editable" class="text-sm w-3.5" tabindex="-1" @click="removeOption(option)">
            &times;
          </button> -->
        </div>
        <div v-if="field.options && !editable" class="pb-1" />
        <v-btn v-else-if="field.options && editable" color="primary" text @click="addOption">
          <v-icon>mdi-plus</v-icon>
          Add option
        </v-btn>
      </div>
    </div>
    <Teleport v-if="isShowFormulaModal" :to="modalContainerEl">
      <FormulaModal
        :field="field"
        :editable="editable && !defaultField"
        :build-default-name="buildDefaultName"
        @close="isShowFormulaModal = false"
      />
    </Teleport>
    <Teleport v-if="isShowConditionsModal" :to="modalContainerEl">
      <ConditionsModal :field="field" :build-default-name="buildDefaultName" @close="isShowConditionsModal = false" />
    </Teleport>
    <DescriptionModal
      :is-open="isShowDescriptionModal"
      :field="field"
      :editable="editable && !defaultField"
      :build-default-name="buildDefaultName"
      @close="isShowDescriptionModal = false"
    />
  </div>
</template>

<script>
// import { IconRouteAltLeft, MathFunctionIcon, NewSectionIcon, TrashXIcon, SettingsIcon } from 'vue-tabler-icons';
import { v4 } from 'uuid';
import NewSectionIcon from '@/components/Icons/NewSectionIcon.vue';
import Contenteditable from './Contenteditable.vue';
import FieldType from './FieldType.vue';
// import PaymentSettings from './PaymentSettings.vue';
import FieldSettings from './FieldSettings.vue';
import FormulaModal from './FormulaModal.vue';
import ConditionsModal from './ConditionsModal.vue';
import DescriptionModal from './DescriptionModal.vue';

export default {
  name: 'TemplateField',
  components: {
    Contenteditable,
    NewSectionIcon,
    // SettingsIcon,
    FieldSettings,
    // PaymentSettings,
    // NewSectionIcon,
    FormulaModal,
    DescriptionModal,
    ConditionsModal,
    // IconRouteAltLeft,
    // TrashXIcon,
    // MathFunctionIcon,
    FieldType,
  },
  inject: ['template', 'save', 'backgroundColor'],
  props: {
    field: {
      type: Object,
      required: true,
    },
    defaultField: {
      type: Object,
      required: false,
      default: null,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    selectedAreaRef: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ['set-draw', 'remove', 'scroll-to'],
  data() {
    return {
      isNameFocus: false,
      showPaymentModal: false,
      isShowFormulaModal: false,
      isShowConditionsModal: false,
      isShowDescriptionModal: false,
      renderDropdown: false,
      showSettingsMenu: false,
    };
  },
  computed: {
    fieldNames: FieldType.computed.fieldNames,
    dropdownBgColor() {
      return ['', null, 'transparent'].includes(this.backgroundColor) ? 'white' : this.backgroundColor;
    },
    schemaAttachmentsIndexes() {
      return (this.template.schema || []).reduce((acc, item, index) => {
        acc[item.attachmentUuid] = index;

        return acc;
      }, {});
    },
    sortedAreas() {
      return (this.field.areas || []).sort((a, b) => {
        return this.schemaAttachmentsIndexes[a.attachmentUuid] - this.schemaAttachmentsIndexes[b.attachmentUuid];
      });
    },
    modalContainerEl() {
      return this.$el.getRootNode().querySelector('#docuseal_modal_container');
    },
    defaultName() {
      return this.buildDefaultName(this.field, this.template.fields);
    },
    areas() {
      return this.field.areas || [];
    },
    mutableSelectedAreaRef() {
      return this.selectedAreaRef;
    },
  },
  created() {
    this.field.preferences ||= {};

    if (this.field.type === 'date') {
      this.field.preferences.format ||=
        Intl.DateTimeFormat()
          .resolvedOptions()
          .locale.endsWith('-US') ||
        new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' })
          .format(new Date())
          .match(/\s(?:CST|CDT|PST|PDT|EST|EDT)$/)
          ? 'MM/DD/YYYY'
          : 'DD/MM/YYYY';
    }
  },
  methods: {
    buildDefaultName(field, fields) {
      if (field.type === 'payment' && field.preferences?.price) {
        const { price, currency } = field.preferences || {};

        const formattedPrice = new Intl.NumberFormat([], {
          style: 'currency',
          currency,
        }).format(price);

        return `${this.fieldNames[field.type]} ${formattedPrice}`;
      }
      const typeIndex = fields.filter(f => f.type === field.type).indexOf(field);

      const suffix = { multiple: this.$t('select'), radio: this.$t('group') }[field.type] || this.$t('field');

      return `${this.fieldNames[field.type]} ${suffix} ${typeIndex + 1}`;
    },
    onNameFocus() {
      this.isNameFocus = true;

      if (!this.field.name) {
        setTimeout(() => {
          this.$refs.name.$refs.contenteditable.innerText = ' ';
        }, 1);
      }
    },
    maybeFocusOnOptionArea(option) {
      const area = this.field.areas.find(a => a.option_uuid === option.uuid);

      if (area) {
        this.$emit('change-selected-area', area);
        // this.selectedAreaRef = area;
      }
    },
    scrollToFirstArea() {
      return this.sortedAreas[0] && this.$emit('scroll-to', this.sortedAreas[0]);
    },
    closeDropdown() {
      document.activeElement.blur();
    },
    addOption() {
      this.field.options.push({ value: '', uuid: v4() });

      this.$nextTick(() => {
        const inputs = this.$refs.options.querySelectorAll('input');

        inputs[inputs.length - 1]?.focus();
      });

      this.save();
    },
    removeOption(option) {
      this.field.options.splice(this.field.options.indexOf(option), 1);

      const optionIndex = this.field.areas.findIndex(a => a.option_uuid === option.uuid);

      if (optionIndex !== -1) {
        this.field.areas.splice(
          this.field.areas.findIndex(a => a.option_uuid === option.uuid),
          1
        );
      }

      this.save();
    },
    maybeUpdateOptions() {
      delete this.field.defaultValue;

      if (!['radio', 'multiple', 'select'].includes(this.field.type)) {
        delete this.field.options;
      }

      if (['radio', 'multiple', 'select'].includes(this.field.type)) {
        this.field.options ||= [{ value: '', uuid: v4() }];
      }

      (this.field.areas || []).forEach(area => {
        if (this.field.type === 'cells') {
          // eslint-disable-next-line no-param-reassign
          area.cellW = (area.w * 2) / Math.floor(area.w / area.h);
        } else {
          // eslint-disable-next-line no-param-reassign
          delete area.cellW;
        }
      });
    },
    onNameBlur() {
      const text = this.$refs.name.$refs.contenteditable.innerText.trim();

      if (text) {
        this.field.name = text;
      } else {
        this.field.name = '';
        this.$refs.name.$refs.contenteditable.innerText = this.defaultName;
      }

      this.isNameFocus = false;

      this.save();
    },
  },
  NewSectionIcon,
};
</script>
