<template>
  <div>
    <div :class="withStickySubmitters ? 'sticky top-0' : ''" style="z-index: 1">
      <field-submitter
        v-model="selectedSubmitter.uuid"
        field-class="py-3 ps-5 pe-2"
        style="background:#FAFAFA;"
        :submitters="submitters"
        :editable="editable && !defaultSubmitters.length"
        @input="
          $emit(
            'change-submitter',
            submitters.find(s => s.uuid === $event)
          )
        "
      />
    </div>
    <div ref="fields" class="fields my-1" @dragover.prevent="onFieldDragover" @drop="reorderFields">
      <Field
        v-for="field in submitterFields"
        :key="field.uuid"
        :data-uuid="field.uuid"
        :field="field"
        :type-index="fields.filter(f => f.type === field.type).indexOf(field)"
        :editable="editable && (!fieldsDragFieldRef || fieldsDragFieldRef !== field)"
        :default-field="defaultFields.find(f => f.name === field.name)"
        :draggable="editable"
        style="background:#FAFAFA"
        @change-selected-area="$emit('change-selected-area', $event)"
        @dragstart="fieldsDragFieldRef = field"
        @dragend="fieldsDragFieldRef = null"
        @remove="removeField"
        @scroll-to="$emit('scroll-to-area', $event)"
        @set-draw="$emit('set-draw', $event)"
      />
    </div>
    <div v-if="submitterDefaultFields.length && editable">
      <hr class="mb-2" />
      <template v-if="isShowFieldSearch">
        <input
          v-model="defaultFieldsSearch"
          placeholder="search_field"
          class="input input-ghost input-xs px-0 text-base mb-2 !outline-0 !rounded bg-transparent w-full"
        />
        <hr class="mb-2" />
      </template>
      <div
        class="overflow-auto relative"
        :style="{
          maxHeight: isShowFieldSearch ? '210px' : '',
          minHeight: isShowFieldSearch ? '210px' : '',
        }"
      >
        <div
          v-if="!filteredSubmitterDefaultFields.length && defaultFieldsSearch"
          class="top-0 bottom-0 text-center absolute flex items-center justify-center w-full flex-col"
        >
          <div>
            {{ $t('field_not_found') }}
          </div>
          <a href="#" class="link" @click.prevent="defaultFieldsSearch = ''">
            {{ $t('clear') }}
          </a>
        </div>
        <div
          v-for="field in filteredSubmitterDefaultFields"
          :key="field.uuid"
          :style="{ backgroundColor }"
          draggable="true"
          class="default-field border border-base-300 rounded rounded-tr-none relative group mb-2"
          @dragstart="onDragstart({ type: 'text', ...field })"
          @dragend="$emit('drag-end')"
        >
          <div class="flex items-center justify-between relative cursor-grab">
            <div class="flex items-center p-1 space-x-1">
              <IconDrag />
              <field-еype :model-value="field.type || 'text'" :editable="false" :button-width="20" />
              <span class="block pl-0.5">
                {{ field.title || field.name }}
              </span>
            </div>
            <span
              v-if="defaultRequiredFields.includes(field)"
              data-tip="required"
              class="text-red-400 text-3xl pr-1.5 tooltip tooltip-left h-8"
            >
              *
            </span>
          </div>
        </div>
      </div>
    </div>
    <v-row v-if="editable && !onlyDefinedFields" class="px-5 py-5 mx-0 my-0" style="background-color: #FAFAFA">
      <v-col
        v-for="(icon, type) in fieldIconsSorted"
        :key="type"
        :cols="4"
        class="px-1 py-0 d-flex flex-column align-center"
      >
        <button
          v-if="fieldTypes.length === 0 || fieldTypes.includes(type)"
          draggable="true"
          class="field-type-button group d-flex align-center justify-center py-3"
          @dragstart="onDragstart({ type: type })"
          @dragend="$emit('drag-end')"
          @click="['file', 'payment'].includes(type) ? $emit('add-field', type) : $emit('set-draw-type', type)"
        >
          <div class="d-flex flex-column align-center">
            <component :is="icon" />
            <span class="text-xs mt-1">
              {{ fieldNames[type] }}
            </span>
          </div>
        </button>
        <!-- <div
          v-else-if="type == 'phone' && (fieldTypes.length === 0 || fieldTypes.includes(type))"
          class="tooltip tooltip-bottom flex"
          :class="{ 'tooltip-bottom-end': withPayment, 'tooltip-bottom': !withPayment }"
          data-tip="Unlock SMS-verified phone number field with paid plan. Use text field for phone numbers without verification."
        >
          <a
            href="https://www.docuseal.co/pricing"
            target="_blank"
            class="opacity-50 flex items-center justify-center border border-dashed border-base-300 w-full rounded relative"
            :style="{ backgroundColor }"
          >
            <div class="w-0 absolute left-0">
              <LockIcon width="18" height="18" stroke-width="1.5" />
            </div>
            <div class="flex items-center flex-col px-2 py-2">
              <component :is="icon" />
              <span class="text-xs mt-1">
                {{ fieldNames[type] }}
              </span>
            </div>
          </a>
        </div> -->
      </v-col>
    </v-row>
    <div
      v-if="fields.length < 4 && editable && withHelp"
      class="text-xs ps-1 pe-3 py-2 mt-4 border border-base-200 rounded"
    >
      <ul class="list-disc list-outside text-caption">
        <li>
          {{ $t('templates.draw_text_field_message') }}
        </li>
        <li>
          {{ $t('templates.grag_drop_field_message') }}
        </li>
        <li>
          {{ $t('templates.click_on_field_and_drawing_message') }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import { LockIcon, CirclePlusIcon } from 'vue-tabler-icons';
import IconDrag from './IconDrag.vue';
import Field from './Field.vue';
import FieldType from './FieldType.vue';
import FieldSubmitter from './FieldSubmitter.vue';

export default {
  name: 'TemplateFields',
  components: {
    Field,
    FieldType,
    FieldSubmitter,
    // CirclePlusIcon,
    IconDrag,
    // LockIcon,
  },
  inject: ['save', 'backgroundColor', 'withPhone', 'withPayment', 'fieldsDragFieldRef'],
  props: {
    fields: {
      type: Array,
      required: true,
    },
    withHelp: {
      type: Boolean,
      required: false,
      default: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    defaultFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    defaultRequiredFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    onlyDefinedFields: {
      type: Boolean,
      required: false,
      default: true,
    },
    drawFieldType: {
      type: String,
      required: false,
      default: '',
    },
    defaultSubmitters: {
      type: Array,
      required: false,
      default: () => [],
    },
    withStickySubmitters: {
      type: Boolean,
      required: false,
      default: true,
    },
    fieldTypes: {
      type: Array,
      required: false,
      default: () => [],
    },
    submitters: {
      type: Array,
      required: true,
    },
    selectedSubmitter: {
      type: Object,
      required: true,
    },
  },
  emits: ['add-field', 'set-draw', 'set-draw-type', 'set-drag', 'drag-end', 'scroll-to-area', 'change-submitter'],
  data() {
    return {
      defaultFieldsSearch: '',
    };
  },
  computed: {
    fieldNames: FieldType.computed.fieldNames,
    fieldIcons: FieldType.computed.fieldIcons,
    isShowFieldSearch() {
      return this.submitterDefaultFields.length > 15;
    },
    fieldIconsSorted() {
      if (this.fieldTypes.length) {
        return this.fieldTypes.reduce((acc, type) => {
          acc[type] = this.fieldIcons[type];

          return acc;
        }, {});
      }
      return this.fieldIcons;
    },
    submitterFields() {
      return this.fields.filter(f => f.submitterUuid === this.selectedSubmitter.uuid);
    },
    submitterDefaultFields() {
      return this.defaultFields.filter(f => {
        return (
          !this.submitterFields.find(field => field.name === f.name) &&
          (!f.role || f.role === this.selectedSubmitter.name)
        );
      });
    },
    filteredSubmitterDefaultFields() {
      if (this.defaultFieldsSearch) {
        return this.submitterDefaultFields.filter(f =>
          f.name.toLowerCase().includes(this.defaultFieldsSearch.toLowerCase())
        );
      }
      return this.submitterDefaultFields;
    },
  },
  methods: {
    onDragstart(field) {
      this.$emit('set-drag', field);
    },
    onFieldDragover(e) {
      const targetField = e.target.closest('[data-uuid]');
      const dragField = this.$refs.fields.querySelector(`[data-uuid="${this.fieldsDragFieldRef.uuid}"]`);

      if (dragField && targetField && targetField !== dragField) {
        const fields = Array.from(this.$refs.fields.children);
        const currentIndex = fields.indexOf(dragField);
        const targetIndex = fields.indexOf(targetField);

        if (currentIndex < targetIndex) {
          targetField.after(dragField);
        } else {
          targetField.before(dragField);
        }
      }
    },
    reorderFields() {
      Array.from(this.$refs.fields.children).forEach((el, index) => {
        if (el.dataset.uuid !== this.fields[index].uuid) {
          const field = this.fields.find(f => f.uuid === el.dataset.uuid);

          this.fields.splice(this.fields.indexOf(field), 1);
          this.fields.splice(index, 0, field);
        }
      });

      // this.save();
    },
    removeSubmitter(submitter) {
      [...this.fields].forEach(field => {
        if (field.submitterUuid === submitter.uuid) {
          this.removeField(field);
        }
      });

      this.submitters.splice(this.submitters.indexOf(submitter), 1);

      if (this.selectedSubmitter === submitter) {
        this.$emit('change-submitter', this.submitters[0]);
      }

      this.save();
    },
    removeField(field) {
      this.fields.splice(this.fields.indexOf(field), 1);

      this.fields.forEach(f => {
        (f.conditions || []).forEach(c => {
          if (c.field_uuid === field.uuid) {
            f.conditions.splice(f.conditions.indexOf(c), 1);
          }
        });
      });

      this.save();
    },
  },
};
</script>
