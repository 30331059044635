<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.075 11.725C15.2917 10.825 16.2417 9.8375 16.925 8.7625C17.6083 7.6875 17.95 6.61667 17.95 5.55C17.95 5.01667 17.8625 4.625 17.6875 4.375C17.5125 4.125 17.275 4 16.975 4C16.1917 4 15.5 4.6625 14.9 5.9875C14.3 7.3125 14 8.80833 14 10.475C14 10.7083 14.0042 10.9292 14.0125 11.1375C14.0208 11.3458 14.0417 11.5417 14.075 11.725ZM3 21V19H5V21H3ZM7 21V19H9V21H7ZM11 21V19H13V21H11ZM15 21V19H17V21H15ZM19 21V19H21V21H19ZM3.4 17L2 15.6L3.6 14L2 12.4L3.4 11L5 12.6L6.6 11L8 12.4L6.4 14L8 15.6L6.6 17L5 15.4L3.4 17ZM15.45 16C14.95 16 14.4917 15.9042 14.075 15.7125C13.6583 15.5208 13.3 15.2083 13 14.775C12.5833 15.0083 12.1542 15.2167 11.7125 15.4C11.2708 15.5833 10.8167 15.7667 10.35 15.95L9.65 14.075C10.1167 13.9083 10.5625 13.7292 10.9875 13.5375C11.4125 13.3458 11.825 13.1417 12.225 12.925C12.1417 12.5583 12.0792 12.1583 12.0375 11.725C11.9958 11.2917 11.975 10.825 11.975 10.325C11.975 7.925 12.45 5.9375 13.4 4.3625C14.35 2.7875 15.5417 2 16.975 2C17.8417 2 18.55 2.32083 19.1 2.9625C19.65 3.60417 19.925 4.5 19.925 5.65C19.925 7.08333 19.4708 8.5 18.5625 9.9C17.6542 11.3 16.3917 12.5583 14.775 13.675C14.8917 13.7917 15.0125 13.8792 15.1375 13.9375C15.2625 13.9958 15.3917 14.025 15.525 14.025C15.9583 14.025 16.4625 13.75 17.0375 13.2C17.6125 12.65 18.1333 11.925 18.6 11.025L20.425 11.875C20.3083 12.1583 20.2167 12.5 20.15 12.9C20.0833 13.3 20.0917 13.65 20.175 13.95C20.3417 13.8667 20.5375 13.725 20.7625 13.525C20.9875 13.325 21.2167 13.075 21.45 12.775L23.025 14C22.5917 14.6 22.0917 15.0833 21.525 15.45C20.9583 15.8167 20.4333 16 19.95 16C19.6 16 19.2875 15.8958 19.0125 15.6875C18.7375 15.4792 18.5083 15.1583 18.325 14.725C17.8583 15.1417 17.3833 15.4583 16.9 15.675C16.4167 15.8917 15.9333 16 15.45 16Z"
      fill-opacity="0.87"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconWritingSign',
};
</script>
