<template>
  <v-menu v-model="showMenu" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon x-small tabindex="0" class="mx-1" color="black" :title="fieldNames[value]" v-bind="attrs" v-on="on">
        <component
          :is="fieldIcons[value]"
          :color="color"
          :width="buttonWidth"
          :class="buttonClasses"
          :stroke-width="1.6"
        />
      </v-btn>
    </template>
    <v-list class="field-types-list" min-width="190">
      <v-list-item-group v-model="listModel" color="primary">
        <v-list-item v-for="(icon, type) in fieldIconsSorted" :key="type" @click.prevent="$emit('input', type)">
          <v-list-item-content class="py-2">
            <v-list-item-title class="d-flex align-center">
              <component :is="icon" class="me-2" />
              {{ fieldNames[type] }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import {
  TextSizeIcon,
  WritingSignIcon,
  CalendarEventIcon,
  PhotoIcon,
  CheckboxIcon,
  PaperclipIcon,
  SelectIcon,
  CircleDotIcon,
  ChecksIcon,
  IconColumns3,
  LetterCaseUpperIcon,
  // RubberStampIcon,
  SquareNumber1Icon,
} from './icons';

export default {
  name: 'FiledTypeDropdown',
  inject: ['withPhone', 'withPayment', 'fieldTypes'],
  props: {
    value: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
    menuClasses: {
      type: String,
      required: false,
      default: 'mt-1.5 bg-base-100',
    },
    menuStyle: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    buttonClasses: {
      type: String,
      required: false,
      default: '',
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    buttonWidth: {
      type: Number,
      required: false,
      default: 18,
    },
  },
  emits: ['input'],
  data() {
    return {
      renderDropdown: false,
      showMenu: false,
      listModel: 0,
    };
  },
  computed: {
    fieldNames() {
      return {
        text: 'Text',
        signature: 'Signature',
        initials: 'Initials',
        date: 'Date',
        number: 'Number',
        image: 'Image',
        file: 'File',
        select: 'Select',
        checkbox: 'Checkbox',
        multiple: 'Multiple',
        radio: 'Radio',
        cells: 'Cells',
        // stamp: 'Stamp',
      };
    },
    fieldIcons() {
      return {
        text: TextSizeIcon,
        signature: WritingSignIcon,
        initials: LetterCaseUpperIcon,
        date: CalendarEventIcon,
        number: SquareNumber1Icon,
        image: PhotoIcon,
        checkbox: CheckboxIcon,
        multiple: ChecksIcon,
        file: PaperclipIcon,
        radio: CircleDotIcon,
        select: SelectIcon,
        cells: IconColumns3,
        // stamp: RubberStampIcon,
      };
    },
    fieldIconsSorted() {
      if (this.fieldTypes.length) {
        return this.fieldTypes.reduce((acc, type) => {
          acc[type] = this.fieldIcons[type];

          return acc;
        }, {});
      }
      return this.fieldIcons;
    },
  },
  mounted() {
    this.listModel = Object.keys(this.fieldIcons).indexOf(this.value);
  },
  methods: {
    closeDropdown() {
      document.activeElement.blur();
    },
  },
};
</script>
