<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 22V16C4 15.45 4.19583 14.9792 4.5875 14.5875C4.97917 14.1958 5.45 14 6 14H18C18.55 14 19.0208 14.1958 19.4125 14.5875C19.8042 14.9792 20 15.45 20 16V22H4ZM6 18H18V16H6V18ZM12 14L7 7C7 5.61667 7.4875 4.4375 8.4625 3.4625C9.4375 2.4875 10.6167 2 12 2C13.3833 2 14.5625 2.4875 15.5375 3.4625C16.5125 4.4375 17 5.61667 17 7L12 14ZM12 11.2L15 7C15 6.16667 14.7083 5.45833 14.125 4.875C13.5417 4.29167 12.8333 4 12 4C11.1667 4 10.4583 4.29167 9.875 4.875C9.29167 5.45833 9 6.16667 9 7L12 11.2Z"
      fill-opacity="0.87"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconRubberStamp',
};
</script>
