<template>
  <div class="dropzone d-flex" @dragover.prevent @drop.prevent="onDropFiles">
    <label
      id="document_dropzone"
      :for="inputId"
      :class="{ 'opacity-50': isLoading || isProcessing }"
      class="dropzone__label"
    >
      <div class="dropzone__inner d-flex align-center justify-center">
        <div class="d-flex flex-column align-center">
          <v-progress-circular v-if="isLoading || isProcessing" indeterminate size="40" color="primary" class="mb-2" />
          <v-icon v-else x-large color="primary" class="mb-2">mdi-cloud-upload-outline</v-icon>
          <div v-if="message" class="font-medium text-lg mb-1">
            {{ message }}
          </div>
          <div class="text-sm">{{ $t('templates.clue_message') }}</div>
        </div>
      </div>
      <form ref="form" class="hidden">
        <input
          :id="inputId"
          ref="input"
          type="file"
          name="files[]"
          :accept="acceptFileTypes"
          multiple
          @change="upload"
        />
      </form>
    </label>
  </div>
</template>

<script>
import Upload from './Upload.vue';

export default {
  name: 'FileDropzone',
  inject: ['baseFetch'],
  props: {
    templateId: {
      type: [Number, String],
      required: true,
    },
    acceptFileTypes: {
      type: String,
      required: false,
      default: 'image/*, application/pdf',
    },
  },
  emits: ['success'],
  data() {
    return {
      isLoading: false,
      isProcessing: false,
    };
  },
  computed: {
    inputId() {
      return `el${
        Math.random()
          .toString(32)
          .split('.')[1]
      }`;
    },
    message() {
      if (this.isLoading) {
        return this.$t('templates.uploading');
      }
      if (this.isProcessing) {
        return this.$t('templates.processing');
      }
      if (this.acceptFileTypes === 'image/*, application/pdf') {
        return this.$t('templates.upload_message');
      }
      return this.$t('templates.upload_message');
    },
  },
  methods: {
    upload: Upload.methods.upload,
    onDropFiles(e) {
      if (
        this.acceptFileTypes !== 'image/*, application/pdf' ||
        [...e.dataTransfer.files].every(f => f.type.match(/(?:image\/)|(?:application\/pdf)/))
      ) {
        this.$refs.input.files = e.dataTransfer.files;

        this.upload();
      } else {
        // eslint-disable-next-line
        alert('Only PDF and images are supported.');
      }
    },
  },
};
</script>
