<template>
  <div v-if="isLoading" class="d-flex justify-center align-center primary--text" style="height: 100%">
    <v-progress-circular indeterminate color="primary" />
  </div>
  <div v-else-if="template" class="template">
    <template-builder :template="template" />
  </div>
</template>

<script>
// Components
import TemplateBuilder from '@/components/TemplateBuilder/Builder.vue';

import contractService from '@/services/contract';
import { v4 } from 'uuid';

export default {
  name: 'TemplateModify',

  components: {
    TemplateBuilder,
  },

  inject: ['media'],

  provide() {
    return {
      save: this.save,
    };
  },

  props: {
    defaultFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    defaultRequiredFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    withSelectedFieldType: {
      type: Boolean,
      required: false,
      default: false,
    },
    withHelp: {
      type: Boolean,
      required: false,
      default: true,
    },
    defaultSubmitters: {
      type: Array,
      required: false,
      default: () => [],
    },
    fieldTypes: {
      type: Array,
      required: false,
      default: () => [],
    },
    onlyDefinedFields: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isLoading: true,
      template: null,
      editable: true,
      selectedSubmitter: null,
      drawFieldType: null,
    };
  },

  computed: {},

  created() {
    this.getTemplate();
  },

  methods: {
    getTemplate() {
      contractService
        .getTemplateById(this.$route.params.id)
        .then(response => {
          this.template = response;

          const existingSubmittersUuids = this.defaultSubmitters.map(name => {
            return this.template.submitters.find(e => e.name === name)?.uuid;
          });

          this.defaultSubmitters.forEach((name, index) => {
            const submitter = this.template.submitters[index] || {};

            submitter.name = name;

            if (existingSubmittersUuids.filter(Boolean).length) {
              submitter.uuid = existingSubmittersUuids[index] || submitter.uuid || v4();
            } else {
              submitter.uuid ||= v4();
            }
          });

          this.selectedSubmitter = this.template ? { ...this.template.submitters[0] } : {};
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateName(value) {
      this.template.name = value;

      this.save();
    },
    // sendTemplate() {
    // },
    save() {
      if (!this.template) return;

      const data = {
        name: this.template.name,
        schema: this.template.schema,
        fields: this.template.fields,
        submitters: this.template.submitters,
      };

      contractService.saveTemplate(data, this.$route.params.id);
    },
  },
};
</script>
