<template>
  <v-dialog v-model="openDialog" max-width="560px">
    <v-card>
      <v-card-title class="d-flex justify-space-between flex-nowrap">
        <span class="text-h5">{{ field.name || buildDefaultName(field, template.fields) }}</span>
        <v-btn icon color="primary" class="ms-8" @click="openDialog = false">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="px-6">
        <form @submit.prevent="saveAndClose">
          <div class="space-y-1 mb-1">
            <v-text-field
              ref="textarea"
              v-model="description"
              label="Description"
              dir="auto"
              :readonly="!editable"
              @input="resizeTextarea"
            ></v-text-field>

            <v-text-field v-model="title" label="Display Title" dir="auto" :readonly="!editable"></v-text-field>
          </div>
          <v-btn type="submit" color="primary" large>
            {{ $t('button.save') }}
          </v-btn>
        </form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DescriptionModal',
  inject: ['save', 'template'],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    buildDefaultName: {
      type: Function,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      description: this.field.description,
      title: this.field.title,
      openDialog: this.isOpen,
    };
  },
  watch: {
    isOpen(value) {
      this.openDialog = value;
    },
    openDialog(value) {
      return !value && this.$emit('close');
    },
    field: {
      handler(value) {
        this.description = value.description;
        this.title = value.title;
      },
      deep: true,
    },
  },
  mounted() {
    this.resizeTextarea();
  },
  methods: {
    saveAndClose() {
      this.field.description = this.description;
      this.field.title = this.title;

      this.save();
      this.$emit('close');
    },
    resizeTextarea() {
      const { textarea } = this.$refs;
      if (!textarea) return;

      textarea.$el.style.height = 'auto';
      textarea.$el.style.height = `${textarea.$el.scrollHeight}px`;
    },
  },
};
</script>
