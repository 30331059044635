<template>
  <span class="absolute d-md-none" style="right:4px;bottom:65px;">
    <v-menu v-model="showMenu" offset-y max-height="400">
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab dark color="primary" v-bind="attrs" v-on="on">
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <template v-if="submitterDefaultFields.length">
        <v-list class="submitters-list">
          <v-list-item-group v-model="listModel" color="primary">
            <v-list-item v-for="field in submitterDefaultFields" :key="field.type">
              <v-list-item-content>
                <v-list-item-title class="d-flex align-center">
                  <component :is="fieldIcons[field.type || 'text']" :stroke-width="1.6" :width="20" />
                  {{ field.title || field.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
      <template v-else>
        <v-list class="submitters-list">
          <v-list-item-group v-model="listModel" color="primary">
            <v-list-item v-for="(icon, type) in fieldIcons" :key="type">
              <v-list-item-content>
                <v-list-item-title class="d-flex align-center" @click.prevent="$emit('select', { type })">
                  <component :is="icon" class="me-2" />
                  <span>{{ fieldNames[type] }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </v-menu>
  </span>
</template>
<script>
import IconButton from '@/components/IconButton.vue';
import FieldType from './FieldType.vue';

export default {
  name: 'MobileFields',
  components: { IconButton },
  inject: ['withPhone', 'withPayment', 'backgroundColor'],
  props: {
    modelValue: {
      type: String,
      required: false,
      default: '',
    },
    fields: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectedSubmitter: {
      type: Object,
      required: true,
    },
    fieldTypes: {
      type: Array,
      required: false,
      default: () => [],
    },
    defaultRequiredFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    defaultFields: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  emits: ['select'],
  data() {
    return {
      showMenu: false,
      listModel: 0,
    };
  },
  computed: {
    ...FieldType.computed,
    submitterFields() {
      return this.fields.filter(f => f.submitterUuid === this.selectedSubmitter.uuid);
    },
    submitterDefaultFields() {
      return this.defaultFields.filter(f => {
        return !f.role || f.role === this.selectedSubmitter.name;
      });
    },
  },
  methods: {
    closeDropdown() {
      document.activeElement.blur();
    },
  },
};
</script>
