<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_37_12420)">
      <path
        d="M0 18.3157L4.7368 5.68427H6.99242L11.7292 18.3157H9.56383L8.41346 15.0902H3.31576L2.1654 18.3157H0ZM3.96989 13.2631H7.75933L5.90972 8.03011H5.8195L3.96989 13.2631Z"
        fill-opacity="0.87"
      />
      <path
        d="M12.2715 18.3158L17.0083 5.68433H19.2639L24.0007 18.3158H21.8353L20.6849 15.0903H15.5872L14.4369 18.3158H12.2715ZM16.2414 13.2632H20.0308L18.1812 8.03017H18.091L16.2414 13.2632Z"
        fill-opacity="0.87"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconLetterCaseUpper',
};
</script>
