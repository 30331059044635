<template>
  <div class="absolute text-center w-full" style="bottom:65px">
    <v-card class="custom-box-shadow py-1 px-3 mb-2">
      <div class="d-flex align-center justify-space-between ">
        <div class="d-flex align-center">
          <component
            :is="fieldIcons[drawField.type]"
            :width="20"
            :height="20"
            class="inline me-2"
            :stroke-width="1.6"
          />
          <span>{{ fieldNames[drawField.type] }}</span>
        </div>
        <v-btn text color="primary" @click.prevent="$emit('cancel')">
          {{ $t('button.cancel') }}
        </v-btn>
      </div>
    </v-card>
    <!-- <span
      class="w-full bg-base-200 px-4 py-2 rounded-md inline-flex space-x-2 mx-auto items-center justify-between mb-2 z-20 md:hidden"
    >
      <div class="flex items-center space-x-2">
        <component :is="fieldIcons[drawField.type]" :width="20" :height="20" class="inline" :stroke-width="1.6" />
        <span>{{ $t('draw_field').replace('{field}', fieldNames[drawField.type]) }}</span>
      </div>
      <a href="#" class="link block text-center" @click.prevent="$emit('cancel')">
        {{ $t('cancel') }}
      </a>
    </span> -->
    <v-card class="custom-box-shadow">
      <field-submitter
        v-model="selectedSubmitter.uuid"
        :submitters="submitters"
        :editable="editable"
        :mobile-view="true"
        field-class="py-3 px-3"
        @new-submitter="save"
        @remove="removeSubmitter"
        @name-change="save"
        @input="
          $emit(
            'change-submitter',
            submitters.find(s => s.uuid === $event)
          )
        "
      />
    </v-card>
  </div>
</template>

<script>
import Field from './Field.vue';
import FieldType from './FieldType.vue';
import FieldSubmitter from './FieldSubmitter.vue';

export default {
  name: 'MobileDrawField',
  components: {
    Field,
    FieldSubmitter,
  },
  inject: ['save', 'backgroundColor'],
  props: {
    drawField: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    submitters: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    selectedSubmitter: {
      type: Object,
      required: true,
    },
  },
  emits: ['change-submitter', 'cancel'],
  computed: {
    fieldNames: FieldType.computed.fieldNames,
    fieldIcons: FieldType.computed.fieldIcons,
  },
  methods: {
    removeSubmitter(submitter) {
      [...this.fields].forEach(field => {
        if (field.submitterUuid === submitter.uuid) {
          this.removeField(field);
        }
      });

      this.submitters.splice(this.submitters.indexOf(submitter), 1);

      if (this.selectedSubmitter === submitter) {
        this.$emit('change-submitter', this.submitters[0]);
      }

      this.save();
    },
    removeField(field) {
      this.fields.splice(this.fields.indexOf(field), 1);

      this.save();
    },
  },
};
</script>

<style lang="scss">
.custom-box-shadow {
  box-shadow: 0px 1px 8px 0px #0000001f, 0px 3px 4px 0px #00000024, 0px 3px 3px -2px #00000033 !important;
}
</style>
