<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 20V7H2V4H15V7H10V20H7ZM16 20V12H13V9H22V12H19V20H16Z" fill-opacity="0.87" />
  </svg>
</template>

<script>
export default {
  name: 'IconSizeText',
};
</script>
